import dayjs from "dayjs";

const useFreeSpinQuest = (gameId: Ref<number | undefined> = ref()) => {
	let unwatchEndDate: (() => void) | undefined;
	let unwatchFreeSpinQuest: (() => void) | undefined;

	const { isOpen, close, open } = useAppModals();
	const isGuest = useIsGuest();
	const loginGuard = useLoginGuard();
	const { checkDailyOpen, openModalOnce } = useWelcomeModal(open, close);
	const { data: questData } = useGetQuestData({
		immediate: !isGuest.value
	});

	const dayInfo = computed(() => questData.value?.data?.dayInfo || {});
	const questInfo = computed(() => questData.value?.data?.questInfo || {});
	const isFreeSpinQuest = computed(() => questInfo.value?.type === "fp_challenge");
	const questTasks = computed(() => dayInfo.value?.tasks || []);

	const activeTaskIndex = computed(() => {
		const indexTask = questTasks.value?.findIndex(({ isCompleted }) => !isCompleted);
		return indexTask === -1 ? questTasks.value?.length : indexTask + 1;
	});

	const prizePool = computed(() =>
		questTasks.value?.reduce((accumulator, task) => accumulator + (task?.prize?.freeSpin?.freeSpinCount ?? 0), 0)
	);

	const questTasksCount = computed(() => questTasks.value?.length);

	const activeTask = computed(() => {
		const indexTask = questTasks.value?.findIndex(({ isCompleted }) => !isCompleted);
		return indexTask === -1 ? questTasks.value?.[0] : questTasks.value?.[indexTask];
	});

	const isGameInTask = computed(() => {
		if (activeTask.value?.action?.type !== "gamesList" && activeTask.value?.action?.type !== "game") {
			return false;
		}

		return activeTask.value?.action?.details?.games?.some((game) => {
			if (typeof game === "object") {
				return game.id === gameId.value;
			}
			if (typeof game === "number") {
				return game === gameId.value;
			}
			return false;
		});
	});

	const getFormatToken = computed(() =>
		dayjs(questInfo.value?.end ?? "").diff(dayjs(), "d") >= 1 ? "DD[d ] HH[h ]mm[m ]" : "HH[h ] mm[m ] ss[s ]"
	);

	const handleFreeSpinQuest = () => {
		if (isFreeSpinQuest.value && !dayInfo.value?.isCompleted) {
			openModalOnce({
				storage: "showFreeSpinWelcomePopup",
				storageSkip: "showFreeSpinFeature",
				modal: "LazyOModalFreeSpinWelcome",
				endDate: questInfo.value?.end ?? ""
			});
			if (isOpen("LazyOModalFreeSpinWelcome")) {
				return;
			}
			checkDailyOpen({
				storage: "showFreeSpinFeature",
				modal: questInfo.value?.isAvailable ? "LazyOModalFreeSpinFeature" : "LazyOModalFreeSpinAlternative"
			});
		}
	};

	const { durationLeft: durationLeftQuestDay, reset: resetQuestDay } = useCountdown({
		timestamp: questInfo.value?.end || "",
		formatToken: getFormatToken.value,
		isOptimized: true
	});

	onMounted(() => {
		loginGuard({
			success: () => {
				handleFreeSpinQuest();

				if (process.client) {
					unwatchEndDate = watch(
						() => questInfo.value?.end,
						(value) => {
							resetQuestDay(value || "", getFormatToken.value);
						}
					);
					unwatchFreeSpinQuest = watch([isGuest, isFreeSpinQuest], ([newAuth, newFreeSpin]) => {
						if (!newFreeSpin && !newAuth) {
							return;
						}
						handleFreeSpinQuest();
					});
				}
			}
		});
	});

	onBeforeUnmount(() => {
		if (unwatchEndDate) {
			unwatchEndDate();
		}
		if (unwatchFreeSpinQuest) {
			unwatchFreeSpinQuest();
		}
	});

	return {
		durationLeftQuestDay,
		dayInfo,
		isFreeSpinQuest,
		questInfo,
		activeTask,
		questData,
		activeTaskIndex,
		isGameInTask,
		prizePool,
		questTasksCount,
		questTasks
	};
};

export default useFreeSpinQuest;
