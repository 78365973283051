const useFlipTheCoin = () => {
	const { data: bonusesData, refresh: refreshBonusesData } = useBonusesData();

	const typeValue = computed(() => bonusesData.value?.flipTheCoin?.reasonValue || "");
	const limitValue = computed(() => bonusesData.value?.flipTheCoin?.reasonType || "");
	const isFlipTheCoinAvailable = computed(() => bonusesData.value?.flipTheCoin?.available);
	const isLimitOffer = computed(() => ["limit_by_promo_offers", "no_offers_found"].includes(limitValue.value));

	const { duration, durationLeft } = useCountdown({
		timestamp: typeValue.value,
		formatToken: "HH[:]mm[:]ss",
		onCountdownStop: refreshBonusesData
	});

	const handleModalClose = () => {
		refreshBonusesData();
	};

	return {
		typeValue,
		limitValue,
		durationLeft,
		duration,
		isFlipTheCoinAvailable,
		isLimitOffer,
		handleModalClose,
		refreshBonusesData
	};
};

export default useFlipTheCoin;
