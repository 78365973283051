<script setup lang="ts">
import type { ToastContainerOptions } from "vue3-toastify";

import type { PrizeDropsLevel } from "@/types";

const { open, close, isOpen } = useAppModals();
const loginGuard = useLoginGuard();
const { data: appInit, refresh: refreshAppInitData } = useAppInitData();
const isGuest = computed(() => appInit.value?.isGuest);
const { refresh: refreshHomeData, bundleOffer } = useHomePage({ immediate: false });
const { refresh: refreshBonusesData } = useGetBonusesData();
const { refresh: refreshRankedLeagueInfo } = useGetRankedLeagueInfo();
const route = useRoute();
const { t } = useT();
const { enterFullscreen } = useFullscreenToggle();
const { refresh: refreshDepositStreak } = useFunzcityDepositStreak({ immediate: false });
const { checkDailyOpen } = useWelcomeModal(open, close);
const { refresh: refreshCompleteProfile } = useCompleteProfile({ immediate: false });
const { isMobile } = useDevice();
const { refresh: refreshCheckRewardsData } = useCheckRewardsData({ immediate: false });
const { isAvailable: isAvailableDailyLogin, refresh: refreshDailyLogin } = useDailyLogin({ immediate: false });
useGoogleOneTap();
const { refresh: refreshScratchCards } = useAppScratchCards();

const handleDailyLogin = async () => {
	if (appInit.value?.dailyLogin?.isActive) {
		await refreshDailyLogin();
	}
};

if (process.client) {
	watch(isGuest, (val) => {
		if (!val) {
			handleDailyLogin();
		}
	});
}

const toastPrizeDrops = (name: string, prize: string, prizeLevel: PrizeDropsLevel) => {
	toast.success(
		`
			<div class="title">${t("Mad Drops")}</div>
			<div class="text">
				${t("User invader won Entries. Keep playing and get gifts.", { name, prize })}
			</div>
		`,
		{
			icon: h("img", {
				src: `/nuxt-img/prize-drops/prize-${PRIZE_DROPS_LEVEL?.[prizeLevel] || "bronze"}.png`,
				width: 40,
				height: 40
			}),
			dangerouslyHTMLString: true,
			containerId: "notification-prize-drops",
			type: toast.TYPE.DEFAULT,
			theme: toast.THEME.DARK,
			position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000,
			limit: 3,
			closeButton: () => h("i", { class: "toast-icon icon-close" }),
			onClose: () => toast.clearAll("notification-prize-drops")
		} as ToastContainerOptions
	);
};

onMounted(() => {
	if ("scrollRestoration" in history) {
		history.scrollRestoration = "manual";
	}

	pushNotificationsDispatchEvent("init");
	const { captcha } = appInit.value;

	if (captcha && captcha.isEnabled && captcha.key) {
		useHead({
			script: [
				{
					src: captcha.url,
					id: captcha.key,
					async: true,
					defer: true
				}
			]
		});
	}
	if (route.query.game) {
		open("LazyOModalGame");
	}
	if (route.query?.action === "register") {
		setTimeout(() => {
			open("LazyOModalWelcome");
		}, 1000);
	}

	if (!isGuest.value && !appInit?.value?.emailConfirmed && !route.query.game) {
		open("LazyOModalEmailConfirm");
	}

	loginGuard({
		success: () => {
			if (appInit.value?.season?.isEnabled) {
				checkDailyOpen({ storage: "showWelcomeSeasonModal", modal: "LazyOModalSeasonWelcome" });
			}
			if (appInit.value?.prizeDrops?.isActive) {
				checkDailyOpen({ storage: "showPrizeDropPopup", modal: "LazyOModalPrizeDropsWelcome" });
			}
			if (appInit.value?.depositStreak?.isActive && !isOpen("LazyOModalWelcome")) {
				checkDailyOpen({ storage: "showDepositStreakWelcome", modal: "LazyOModalDepositStreakWelcome" });
			}
			if (isAvailableDailyLogin.value) {
				checkDailyOpen({ storage: "showDailyLogin", modal: "LazyOModalDailyLogin" });
			}
			if (bundleOffer.value) {
				checkDailyOpen({ storage: "showBundleOffer", modal: "LazyOModalBundle" });
			}
		}
	});
});

useEvent(["nuxt:openLogin"], () => {
	open("LazyOModalLogin");
});
useEvent(["nuxt:openSignup"], () => {
	const { isOneClickRegister } = useOtpGetFlow();

	if (isOneClickRegister) {
		open("LazyOModalOneClickSignup");
		return;
	}
	open("LazyOModalSignup");
});
useEvent(["nuxt:openOneClickComplete"], () => {
	open("LazyOModalOneClickSignupComplete");
});
useEvent(["nuxt:closeSignup"], () => {
	close("LazyOModalSignup");
});
useEvent(["nuxt:openEmailConfirm"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalEmailConfirm", { immediateRequest: detail.immediateRequest });
});
useEvent(["nuxt:closeEmailConfirm"], () => {
	close("LazyOModalEmailConfirm");
});
useEvent(["nuxt:openPhoneConfirmation"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalPhoneConfirmation", detail);
});
useEvent(["nuxt:openRacesGames"], () => {
	open("LazyOModalRacesGames");
});
useEvent(["nuxt:closeRacesGames"], () => {
	close("LazyOModalRacesGames");
});
useEvent(["nuxt:openJackpotsGames"], () => {
	open("LazyOModalJackpotsGames");
});
useEvent(["nuxt:setWelcomePopup"], (event: Event) => {
	const { detail } = event as CustomEvent;
	if (detail.isOpen) {
		open("LazyOModalWelcome");
	} else {
		close("LazyOModalWelcome");
	}
});
useEvent(["nuxt:depositStreakRefresh"], () => {
	refreshDepositStreak();
});
useEvent(["nuxt:depositStreakHiW"], () => {
	open("LazyOModalDepositStreakHowItWorks");
});
useEvent(["nuxt:openDepositStreak"], () => {
	open("LazyOModalDepositStreak");
});
useEvent(["nuxt:refreshHomeData"], () => {
	refreshHomeData();
});
useEvent(["nuxt:playerInit"], () => {
	refreshAppInitData();
});
useEvent(["nuxt:toggleRegistrationCompletePopup"], (event) => {
	if ((event as CustomEvent).detail.isOpen === true) {
		open("LazyOModalRegistrationComplete");
		return;
	}
	close("LazyOModalRegistrationComplete");
});
useEvent(["nuxt:openMoneyBox"], () => {
	open("LazyOModalMoneyBox");
});
useEvent(["nuxt:openFlipTheCoin"], () => {
	open("LazyOModalFlipTheCoin");
});
useEvent(["nuxt:openFlipTheCoinUsed"], () => {
	open("LazyOModalFlipTheCoinUsed");
});
useEvent(["nuxt:openTermsDialog"], (event) => {
	open("LazyOModalTermsDialog", {
		type: (event as CustomEvent).detail.type,
		anchor: (event as CustomEvent).detail.anchor
	});
});
useEvent(["LEAVE_CASH"], () => {
	enterFullscreen();
});
useEvent(["OPEN_PHONE_VERIFY_POPUP"], () => {
	loginGuard({
		success: () => {
			open("LazyOModalPhoneConfirmation");
		}
	});
});
useEvent(["LEAVE_CASH"], () => {
	enterFullscreen();
});
useEvent(["UPDATE_NUXT_DATA"], () => {
	loginGuard({
		success: () => {
			Promise.all([
				refreshRankedLeagueInfo(),
				refreshBonusesData(),
				refreshCompleteProfile(),
				refreshCheckRewardsData()
			]);
		}
	});
});
useEvent(["nuxt:openSeason"], () => {
	open("LazyOModalSeason");
});
useEvent(["nuxt:scratchCardsRefresh"], () => {
	refreshScratchCards();
});
useEvent(["WEBSOCKET_RECONNECT"], () => {
	navigateTo("/", { external: true });
});
useEvent(["LEAVE_CASH"], () => {
	setTimeout(() => {
		document.body.style.overflow = "initial";
	}, 300);
});

useQuestsSocket();
useWelcomePopup();
useInitRegisterComplete();
usePopupsInit();
useMysteryBoxInit();
usePresetPackageInit();
useRankedLeagueSockets();
useCheckPromoOffer(open);
useAppRefreshSocket(t);
useExitModal();
useTournamentNotification();
useSeasonSocket();
useUploadDocumentsToast("dark");
usePrizeDropSocket({ t, open, showToast: toastPrizeDrops });
</script>
<template>
	<div>
		<NuxtPage />
		<ClientOnly>
			<LazySupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>

<style lang="scss">
::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: var(--сirebon);
}
.Toastify {
	&__toast {
		@include media-breakpoint-down(lg) {
			margin-bottom: 8px;
		}
	}

	&__toast:has(.badge) {
		overflow: visible;
	}

	.icon-checked {
		background-image: url(/assets/icons/24/success.svg);
	}

	.icon-close {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
	}

	.badge {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 10px;
		left: -13px;
		width: 26px;
		height: 20px;
		border-radius: gutter(0.5);
		color: var(--coro);
		font-size: 12px;
		background-color: var(--camaguey);
		z-index: 123123;
		&--hidden {
			display: none;
		}
	}
}

#email-confirm-container {
	width: 320px;
	max-width: 100%;

	@include media-breakpoint-down(md) {
		transform: translateX(-50%);
		left: 50%;
		top: 8px;
	}

	.Toastify {
		&__toast-body {
			color: var(--clara);
		}
	}
}

#notification-center-container,
#notification-center-container-email {
	width: 328px;
	max-width: 100%;

	@include media-breakpoint-down(lg) {
		bottom: auto;
		left: auto;
		top: 64px;
	}

	.icon-close {
		flex-shrink: 0;
		width: 18px;
		height: 18px;
		background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
	}

	.notification {
		&-center {
			display: flex;
			flex-direction: column;
		}

		&-title {
			color: var(--cannes);
			font-weight: 600;
			line-height: 21px;
		}

		&-text {
			color: var(--chanda);
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
		}

		&-more {
			color: var(--changchun);
		}
	}

	.Toastify {
		&__close-button {
			background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
			width: 20px;
			height: 20px;
			opacity: 1;

			&:hover {
				opacity: 0.7;
			}

			svg {
				display: none;
			}
		}
		&__toast {
			border-radius: 8px;
			border: 1px solid var(--comilla);
			background-color: var(--celaya);
			padding: gutter(2);
			min-height: 65px;
		}
		&__toast-body {
			color: var(--clara);
		}
		&__toast-icon {
			width: 36px;
			margin-right: gutter(1);

			img {
				width: 36px;
				height: 36px;
			}
		}

		&__toast-body > div:last-child > div {
			display: flex;
			flex-direction: column;
		}
	}
}
#notification-quest-progress {
	--toastify-toast-width: 265px;

	@include media-breakpoint-down(lg) {
		bottom: auto;
		left: 12px;
		top: 76px;
		max-width: 265px;

		@media (orientation: landscape) {
			top: 12px;
			left: 60px;
		}
	}

	.Toastify {
		&__toast-body {
			flex-direction: row-reverse;
			align-items: flex-start;
			color: var(--clara);

			& > div:last-child {
				font-weight: 500;
				text-transform: uppercase;
				color: var(--cannes);
			}
		}

		&__toast-icon {
			margin: 0;
			width: auto;

			.icon-close {
				flex-shrink: 0;
				width: 12px;
				height: 12px;
				background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
			}
		}
	}
}

#upload-document-notification,
#upload-document-cash-notification {
	.Toastify {
		&__toast-icon {
			margin: 0;
			width: auto;

			.icon-close {
				visibility: hidden;
				width: 0;
			}
		}
	}

	.Toastify__toast {
		min-width: 328px;
		width: 328px;
		top: 90px;
		height: auto;
		min-height: 72px;
		max-height: 116px;
		padding: 16px;
		background-color: var(--celaya);
		border-radius: 8px;
		border: 1px solid var(--comilla);
		box-shadow:
			0px 10px 15px -3px var(--chaykovskiy),
			0px 4px 6px -2px var(--chengzihe);

		@media (max-width: 1024px) {
			width: 328px;
		}

		@media only screen and (max-width: 480px) {
			top: 110px;
		}

		.Toastify__toast-body {
			white-space: normal;
			margin: 0;
		}

		.Toastify__close-button {
			display: block;
			flex-shrink: 0;
			width: 20px;
			height: 20px;
			background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
		}

		.Toastify__close-button > svg {
			height: 10px;
			width: 10px;
		}

		.toast-content-container {
			display: flex;
			flex-direction: row;
		}

		.toast-img-container {
			display: flex;
			align-items: center;
		}

		.toast-img {
			width: 40px;
			height: 40px;
		}

		.toast-text {
			padding: 0 16px;
			line-break: auto;
			white-space: pre-line;
		}

		.toast-title {
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			color: var(--cannes);
		}

		.toast-message {
			color: var(--chanda);
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			padding-top: 8px;
		}
	}
}

#upload-document-cash-notification {
	.Toastify__toast {
		top: 0px;

		@media only screen and (max-width: 480px) {
			top: 20px;
		}
	}
}

.Toastify__toast--success.notification-season {
	width: 330px;
	margin-left: auto;
	height: 110px;
	background: var(--celaya);
	padding: 8px 8px 8px 120px;

	@include media-breakpoint-down(lg) {
		margin: 0 auto;
		top: 76px;

		@media (orientation: landscape) {
			top: 12px;
		}
	}

	.Toastify {
		&__toast-body {
			flex-direction: row-reverse;
			align-items: flex-start;
			height: 110px;

			& > div:last-child > div {
				display: flex;
				flex-direction: column;
				gap: gutter(0.7);
			}
		}

		&__toast-icon {
			width: auto;
			height: 108px;
			position: absolute;
			top: 0;
			left: 0;
			margin: 0;

			img {
				width: auto;
				height: 108px;
			}
		}
	}

	.notification {
		&-center {
			display: flex;
			flex-direction: column;
		}

		&-content {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		&-image {
			width: 154px;
			height: 128px;
			position: absolute;
			top: 0;
			left: 0;
		}

		&-title {
			color: var(--сirebon);
			font-family: var(--font-fredoka);
			font-size: 16px;
			font-weight: 700;
		}

		&-level {
			font-size: 14px;
			line-height: 20px;
			color: var(--coro);
			font-weight: 500;

			.prize {
				font-family: var(--font-fredoka);
				font-size: 16px;
				line-height: 20px;
				font-weight: 700;
				color: var(--coro);
				display: inline-flex;
				align-items: center;
				gap: 4px;

				&::before {
					content: "";
					width: 20px;
					height: 20px;
					background: url("~/assets/icons/12/secret-coins.svg") center/cover no-repeat;
				}
			}
		}
	}
}

#notification-prize-drops {
	width: 328px;
	z-index: 9998;

	@include media-breakpoint-down(lg) {
		left: 50%;
		top: 16px;
		transform: translateX(-50%);
	}

	.Toastify {
		&__toast {
			border-radius: 8px;
			border: 1px solid var(--comilla);
			background: var(--celaya);
			width: 100%;
			max-width: 328px;
			box-shadow: 0px 11px 18px 0px var(--chaykovskiy);
		}

		&__toast-body {
			align-items: flex-start;

			> div:last-child > div {
				display: flex;
				flex-direction: column;
			}

			.title {
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
			}

			.text {
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
			}
		}

		&__toast-icon {
			width: auto;
		}

		&__close-button {
			display: flex;
			width: 16px;
			height: 16px;
			opacity: 1;
			background: url(/assets/icons/16/close.svg) center/cover no-repeat;

			svg {
				display: none;
			}
		}
	}
}

.grecaptcha-badge {
	visibility: hidden;
}
</style>
