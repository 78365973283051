import useAsyncFetch from "./useAsyncFetch";

const useOtpGetFlow = (isGetFlowImmediate?: boolean) => {
	const route = useRoute();
	const { registrationSource, trafficSource } = route.query;
	const registrationQuery = locationQueryValueToString(registrationSource);
	const trafficQuery = locationQueryValueToString(trafficSource);
	const isOneClickRegister = registrationQuery === "traffic" && trafficSource === "noSocial";

	const rest = useAsyncFetch({
		path: "/rest/otp/get-flow/",
		fetchOptions: {
			headers: {
				Accept: "application/json"
			},
			body: {
				registration_source: registrationQuery,
				traffic_source: trafficQuery
			}
		},
		method: "post",
		options: { immediate: isGetFlowImmediate || !!(registrationSource && trafficSource), cached: true, lazy: true }
	});

	return {
		registrationQuery,
		trafficQuery,
		isOneClickRegister,
		...rest
	};
};

export default useOtpGetFlow;
