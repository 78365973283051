import type { CookieOptions, CookieRef } from "#app";

// Make cookie use state across app
// We'll leave it here until they solve this problem.
// https://github.com/nuxt/nuxt/issues/13020
const useStatefulCookie = <T = string>(
	name: string,
	cookieOptions: CookieOptions<T> & {
		readonly?: false;
	}
): CookieRef<T> => {
	const cookie = useCookie<T>(name, cookieOptions);
	const state = useState<T>(name, () => cookie.value || ("" as T));
	watch(
		state,
		() => {
			cookie.value = state.value;
		},
		{ deep: true }
	);

	return state;
};

export default useStatefulCookie;
