import { toast } from "vue3-toastify";

import useEvent from "./useEvent";

const messagesDictionary = {
	uploaded: `Document uploaded ${"&#10"} successfully`,
	rejected: "Document rejected",
	rejectedMsg: `We accept PDF, GIF, JPG, and PNG files no larger than 15 MB`
};

const sourceDictionary = {
	cash: "cash",
	profile: "profile"
};

const statusDictionary = {
	uploaded: "uploaded",
	rejected: "rejected"
};

export type ToastTheme = "auto" | "light" | "dark" | "colored";
export interface THEME {
	AUTO: ToastTheme;
	LIGHT: ToastTheme;
	DARK: ToastTheme;
	COLORED: ToastTheme;
}

const useUploadDocumentsToast = (theme?: string, iconConfirmed?: string, iconRejected?: string) => {
	const toastTheme = () => {
		if (!theme) {
			return toast.THEME.AUTO;
		}
		return toast.THEME[theme.toUpperCase() as keyof THEME] ?? toast.THEME.AUTO;
	};

	const showDocumentToast = ({
		containerId,
		title,
		message = "",
		iconSrc
	}: {
		containerId: string;
		title: string;
		message?: string;
		iconSrc: string;
	}) => {
		toast.clearAll();

		const content = message
			? `
				<div class="toast-content-container">
					<div class="toast-img-container">
						<img class="toast-img" src=${iconSrc} alt="attachment-icon" />
					</div>
					<div>
						<div class="toast-title toast-text">${title}</div>
						<div class="toast-message toast-text">${message}</div>
					</div>
				</div>
			`
			: `
				<div class="toast-content-container">
					<div class="toast-img-container">
						<img class="toast-img" src=${iconSrc} alt="attachment-icon" />
					</div>
					<div class="toast-title toast-text">${title}</div>
				</div>
			`;
		toast[message ? "error" : "success"](content, {
			containerId,
			icon: h("i", { class: "toast-icon, icon-close" }),
			theme: toastTheme(),
			position: toast.POSITION.TOP_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000,
			dangerouslyHTMLString: true
		});
	};

	useEvent(["UPLOAD_DOCUMENTS_ALERT"], (event: Event) => {
		const { detail } = event as CustomEvent;
		const {
			status,
			source
		}: {
			status: string;
			source: string;
		} = detail;
		const containerId =
			source === sourceDictionary.cash ? "upload-document-cash-notification" : "upload-document-notification";

		if (status === statusDictionary.rejected) {
			showDocumentToast({
				containerId,
				title: messagesDictionary.rejected,
				message: messagesDictionary.rejectedMsg,
				iconSrc: iconRejected ?? "/nuxt-img/account/doc-rejected.png"
			});
			return;
		}

		if (status === statusDictionary.uploaded) {
			showDocumentToast({
				containerId,
				title: messagesDictionary.uploaded,
				iconSrc: iconConfirmed ?? "/nuxt-img/account/doc.png"
			});
		}
	});
};

export default useUploadDocumentsToast;
