const useEventsCollector = () => {
	const startDate = new Date(Date.UTC(2024, 9, 21, 4, 0, 0));
	const endDate = new Date(Date.UTC(2024, 9, 25, 4, 0, 0));

	const { durationExceeded: exceededStart } = useCountdown({ timestamp: startDate.toString(), isOptimized: true });
	const { durationExceeded: exceededEnd } = useCountdown({ timestamp: endDate.toString(), isOptimized: true });

	const isEventBox = computed(() => exceededStart.value && !exceededEnd.value);

	const startEventDate = new Date(Date.UTC(2024, 10, 18, 5, 0, 0));
	const endEventDate = new Date(Date.UTC(2024, 10, 30, 5, 0, 0));

	const { durationExceeded: exceededEventStart } = useCountdown({
		timestamp: startEventDate.toString(),
		isOptimized: true
	});
	const { durationExceeded: exceededEventEnd } = useCountdown({
		timestamp: endEventDate.toString(),
		isOptimized: true
	});

	const isEventTheme = computed(() => exceededEventStart.value && !exceededEventEnd.value);

	return {
		isEventBox,
		isEventTheme
	};
};

export default useEventsCollector;
