const useAccountChangePassword = (minPasswordLength = 5, onSuccessChangePassword: () => void, location = "login") => {
	const { t } = useT();
	const { data: appInitData, refresh } = useAppInitData();
	const { close, open } = useAppModals();
	const route = useRoute();
	const isGuest = useIsGuest();
	const { "change-password": resetPasswordState } = route.query;

	const isGuestCookie = useCookie("isGuest", { path: "/" });
	const isLoading = ref(false);
	const errorMessage = ref("");

	const oldPassword = ref("");
	const isOldPasswordTouched = ref(false);
	const isOldPasswordValid = computed(() => validateStringLength(oldPassword.value, minPasswordLength));
	const isOldPasswordShowError = computed(
		() => (!isOldPasswordValid.value && isOldPasswordTouched.value) || !!errorMessage.value
	);

	const oldPasswordErrorMessage = computed(
		() =>
			errorMessage.value ||
			(!oldPassword.value ? t("Please enter your password") : t("Password must be at least 6 characters long"))
	);

	const newPassword = ref("");
	const isNewPasswordTouched = ref(false);
	const isNewPasswordValid = computed(() => validateStringLength(newPassword.value, minPasswordLength));
	const isNewPasswordShowError = computed(() => !isNewPasswordValid.value && isNewPasswordTouched.value);
	const newPasswordErrorMessage = computed(() =>
		!newPassword.value ? t("Please enter your password") : t("Password must be at least 6 characters long")
	);

	const confirmPassword = ref("");
	const isConfirmPasswordTouched = ref(false);
	const isConfirmPasswordValidLength = computed(() => validateStringLength(confirmPassword.value, minPasswordLength));
	const isConfirmPasswordValidMatch = computed(() => validatePasswordMatch(newPassword.value, confirmPassword.value));
	const isConfirmPasswordShowError = computed(
		() => (!isConfirmPasswordValidLength.value || !isConfirmPasswordValidMatch.value) && isConfirmPasswordTouched.value
	);
	const confirmPasswordErrorMessage = computed(() => {
		if (!confirmPassword.value) {
			return t("Please enter your password");
		}
		if (!isConfirmPasswordValidLength.value) {
			return t("Password must be at least 6 characters long");
		}

		return t("Passwords don't match");
	});

	const hasAutoPassword = computed(() => appInitData.value?.hasAutoPassword);
	const isSubmitBtnEnabled = computed(
		() =>
			(resetPasswordState || hasAutoPassword.value || isOldPasswordValid.value) &&
			isNewPasswordValid.value &&
			isConfirmPasswordValidLength.value &&
			isConfirmPasswordValidMatch.value
	);

	const resetError = () => {
		if (errorMessage.value) {
			errorMessage.value = "";
		}
	};

	const resetFormFields = () => {
		oldPassword.value = "";
		newPassword.value = "";
		confirmPassword.value = "";
		isOldPasswordTouched.value = false;
		isNewPasswordTouched.value = false;
		isConfirmPasswordTouched.value = false;
	};

	const { execute: handleSubmit } = useAsyncFetch({
		path: "/rest/players/change-password/",
		method: "post",
		fetchOptions: () => ({
			body: {
				...(!hasAutoPassword.value && { password_old: oldPassword.value }),
				password: newPassword.value,
				password_repeat: confirmPassword.value
			},
			onRequest: () => {
				resetError();
				isLoading.value = true;
			},
			onResponse: ({ response }) => {
				isLoading.value = false;
				if (!response._data.success) {
					errorMessage.value = response._data.errors?.password_old?.[0] || response._data.message;

					return;
				}
				if (appInitData.value) {
					appInitData.value.hasAutoPassword = false;
				}
				onSuccessChangePassword();
				resetFormFields();

				dispatchGAEvent({
					event: "forgot_password",
					location,
					step: "success"
				});

				close("LazyOModalAccountChangePassword");
			},
			onRequestError: () => {
				isLoading.value = false;
			},
			onResponseError: () => {
				isLoading.value = false;
			}
		}),
		options: { immediate: false, lazy: true }
	});

	const { execute: handleSubmitRestorePassword } = useAsyncFetch({
		path: "/rest/password/restore/",
		method: "post",
		fetchOptions: () => ({
			body: {
				password: newPassword.value,
				password_repeat: confirmPassword.value
			},
			onRequest: () => {
				resetError();
				isLoading.value = true;
			},
			onResponse: async ({ response }) => {
				isLoading.value = false;
				if (response._data.success) {
					await navigateTo({
						path: "/",
						query: {}
					});

					if (appInitData.value) {
						appInitData.value.hasAutoPassword = false;
					}
					onSuccessChangePassword();

					dispatchGAEvent({
						event: "forgot_password",
						location,
						step: "success"
					});

					close("LazyOModalAccountChangePassword");
					return;
				}

				errorMessage.value = response._data.errors?.password_old?.[0] || response._data.message;
			},
			onRequestError: () => {
				isLoading.value = false;
			},
			onResponseError: () => {
				isLoading.value = false;
			}
		}),
		options: { immediate: false, lazy: true }
	});

	const { execute: checkRestoreLink } = useAsyncFetch({
		path: "/rest/password/confirm-request-restore-by-token/",
		method: "post",
		fetchOptions: () => ({
			body: {
				token: resetPasswordState as string
			},
			onResponse: async ({ response }) => {
				if (!response._data.success) {
					open("LazyOModalForgotPasswordFailed");
					return;
				}

				isGuestCookie.value = "false";
				await refresh();
				isGuest.value = false;
				open("LazyOModalAccountChangePassword");
			},
			onRequestError: () => {
				isLoading.value = false;
			},
			onResponseError: () => {
				isLoading.value = false;
			}
		}),
		options: { immediate: false, lazy: true }
	});

	return {
		resetPasswordState,
		isLoading,
		oldPassword,
		isOldPasswordTouched,
		isOldPasswordShowError,
		oldPasswordErrorMessage,
		newPassword,
		isNewPasswordTouched,
		isNewPasswordShowError,
		newPasswordErrorMessage,
		confirmPassword,
		isConfirmPasswordTouched,
		isConfirmPasswordShowError,
		confirmPasswordErrorMessage,
		isSubmitBtnEnabled,
		hasAutoPassword,
		resetError,
		checkRestoreLink,
		handleSubmit,
		handleSubmitRestorePassword
	};
};

export default useAccountChangePassword;
