import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

type LuckySpinDataResponse = ExtractFromAPI<v1.paths, "/rest/rank-league/wheel/lucky-spin/config/", "get">;

type SectorData<T> = { entries?: T; coins?: T };
type Sectors<T> = (SectorData<T> | SectorData<T>[])[];

const prepareSectorsData = <T>(data: Sectors<T>): SectorData<T>[] =>
	data.reduce<SectorData<T>[]>((acc, element) => {
		if (Array.isArray(element)) {
			const transformedArray = element.reduce(
				(subAccumulator, subElement) => ({
					entries: subElement.entries ?? subAccumulator.entries,
					coins: subElement.coins ?? subAccumulator.coins
				}),
				{ entries: undefined, coins: undefined }
			);
			acc.push(transformedArray);
		} else {
			acc.push(element);
		}
		return acc;
	}, []);

const getMaxPrizes = (data: SectorData<number>[]) => ({
	entries: Math.max(...data.map((obj) => obj?.entries ?? 0)),
	coins: Math.max(...data.map((obj) => obj?.coins ?? 0))
});

const useGetLuckySpinData = ({ immediate = true, cached = true }: { immediate?: boolean; cached?: true } = {}) => {
	const isGuest = useIsGuest();

	return useAsyncFetch({
		path: "/rest/rank-league/wheel/lucky-spin/config/",
		method: "get",
		options: {
			immediate: immediate && isGuest.value === false,
			watch: [() => isGuest.value],
			cached,
			transform: (data) => {
				const { auto, availableDate, endpoint, streakCount, wheels, available } = data as LuckySpinDataResponse;

				const wheelId = wheels ? Object.keys(wheels)[0] : "1";
				const wheelSectors: Sectors<number> = wheels ? Object.values(wheels)[0]?.sectors || [] : [];
				const wheelSectorsData = prepareSectorsData(wheelSectors);
				const wheelMaxValues = getMaxPrizes(wheelSectorsData);

				return {
					...data,
					wheelSectorsData,
					wheelMaxValues,
					wheelId,
					wheelAvailable: available,
					wheelConfig: {
						auto,
						availableDate,
						endpoint,
						streakCount,
						wheels
					}
				};
			}
		}
	});
};

export default useGetLuckySpinData;
