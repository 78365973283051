import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

const prependSlash = (str?: string) => {
	if (!str) {
		return str;
	}
	return str.startsWith("/") ? str : `/${str}`;
};

type BonusesResponse = ExtractFromAPI<v1.paths, "/rest/bonus-offer/get-bonuses/", "get">;
type DailyWheel = NonNullable<BonusesResponse["dailyWheel"]>;

const useGetBonusesData = () => {
	const isGuest = useIsGuest();

	return useAsyncFetch({
		path: "/rest/bonus-offer/get-bonuses/",
		method: "get",
		options: {
			immediate: isGuest.value === false,
			watch: [() => isGuest.value],
			cached: true,
			transform: (data) => {
				const isMagicBoxAvailable = !!(
					data.dailyChests?.available ||
					data.dailyChests?.reasonType === "phone" ||
					data.dailyChests?.reasonType === "balance"
				);

				const { available, wheels, prizes, auto, availableDate, endpoint, streakCount, streakCurrent } =
					data?.dailyWheel as DailyWheel & { streakCurrent: number | string | undefined | null };

				const wheelId = wheels ? Object.keys(wheels)[0] : "1";

				const wheelPrizeFund = prizes?.reduce(
					(acc, item) => {
						acc.entries += item.entries || 0;
						acc.coins += item.coins || 0;
						return acc;
					},
					{} as Record<string, number>
				);

				return {
					...data,
					wheelPrizeFund,
					wheelId,
					wheelAvailable: available,
					wheelConfig: {
						auto,
						availableDate,
						endpoint,
						streakCount,
						streakCurrent,
						wheels
					},
					isMagicBoxAvailable,
					audios: [
						{
							name: "soundActiveChests",
							mp3: prependSlash(data.dailyChests?.soundActiveChests?.[0]?.mp3),
							ogg: prependSlash(data.dailyChests?.soundActiveChests?.[0]?.ogg),
							arguments: {
								loop: true
							}
						},
						{
							name: "soundDisabledChests",
							mp3: prependSlash(data.dailyChests?.soundDisabledChests?.[0]?.mp3),
							ogg: prependSlash(data.dailyChests?.soundDisabledChests?.[0]?.ogg),
							arguments: {
								loop: true
							}
						},
						{
							name: "soundOpenChest",
							mp3: prependSlash(data.dailyChests?.soundOpenChest?.[0]?.mp3),
							ogg: prependSlash(data.dailyChests?.soundOpenChest?.[0]?.ogg),
							arguments: {}
						}
					] as const
				};
			}
		}
	});
};

export default useGetBonusesData;
