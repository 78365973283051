import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

const useCompleteProfile = ({ immediate = false, cached }: { immediate?: boolean; cached?: true } = {}) => {
	const isGuest = useIsGuest();
	const { data: appInitData, refresh: refreshAppInitData } = useAppInitData();
	const EASY_PROFILE_FIELDS = ["name", "surname", "birthday", "zip"];
	const FULL_PROFILE_FIELDS = ["name", "surname", "city", "address", "address2", "zip", "state", "phone", "birthday"];

	const { data, refresh, ...rest } = useAsyncFetch({
		path: "/rest/player/profile/",
		method: "get",
		options: {
			key: "playerProfile",
			cached,
			immediate: immediate && !isGuest.value
		},
		fetchOptions: () => ({
			onResponse: ({ response }) => {
				const { profileCompleted } = response?._data?.data ?? {};
				if (!appInitData.value || !profileCompleted) {
					return;
				}
				appInitData.value.profileCompleted = profileCompleted;
				refreshAppInitData();
			}
		})
	});

	const easyProfileEnabled = computed(() => !!data.value?.data && !!data.value.data?.easyProfileModuleEnabled);
	const fullProfileEnabled = computed(() => !!data.value?.data && !data.value.data?.easyProfileModuleEnabled);
	const easyProfileCompleted = computed(() => !!data.value?.data && !!data.value.data?.easyProfileCompleted);

	const fullProfileFields = computed(() => {
		if (!data.value?.data?.uncompletedProfileFields?.length) {
			return FULL_PROFILE_FIELDS;
		}

		if (data.value?.data?.uncompletedProfileFields?.includes("name")) {
			return data.value?.data?.uncompletedProfileFields;
		}

		return [...EASY_PROFILE_FIELDS, ...data.value?.data?.uncompletedProfileFields];
	});

	const easyProfileVisibleInput = (field: string) => fullProfileFields.value?.includes(field);

	const visibleField = (field: string) => {
		if (easyProfileEnabled.value && !easyProfileCompleted.value) {
			return EASY_PROFILE_FIELDS.includes(field);
		}

		return fullProfileFields.value?.includes(field);
	};

	return {
		data,
		...rest,
		easyProfileEnabled,
		fullProfileEnabled,
		easyProfileCompleted,
		refresh,
		visibleField,
		easyProfileVisibleInput
	};
};

export default useCompleteProfile;
