import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";

const useGetTournamentData = ({
	options = {},
	gameId = ref()
}: { gameId?: Ref<number | undefined>; options?: { immediate?: boolean; cached?: boolean } } = {}) => {
	const { data: appInitData } = useAppInitData();

	return useAsyncFetch({
		path: "/rest/tournament/",
		method: "get",
		options: {
			...options,
			immediate: options.immediate,
			cached: options.cached === false ? undefined : true,
			watch: [() => appInitData.value?.gameMode],
			transform: (data) => ({ ...data, isActiveStatus: !Array.isArray(data?.data) })
		},
		fetchOptions: () => ({
			query: {
				gameId: gameId.value
			}
		})
	});
};

export default useGetTournamentData;
