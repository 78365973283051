const useAppScratchCards = () => {
	const { data: appInit } = useAppInitData();
	const isGuest = useIsGuest();
	const loginGuard = useLoginGuard();
	const { isOpen, open, close } = useAppModals();
	const { openModalOnce, checkDailyOpen } = useWelcomeModal(open, close);
	const { data, refresh } = useScratchCard({ immediate: false });

	const handleShowPopups = async () => {
		if (isGuest.value || !appInit.value?.scratchCardLottery?.isActive) {
			return;
		}
		await refresh();
		openModalOnce({
			storage: "showWelcomeScratchCards",
			storageSkip: "showFeatureScratchCards",
			modal: "LazyOModalScratchCardsWelcome",
			endDate: data.value?.finishedAt
		});
		if (!isOpen("LazyOModalScratchCardsWelcome")) {
			checkDailyOpen({ storage: "showFeatureScratchCards", modal: "LazyOModalScratchCardsFeature" });
		}
	};

	if (process.client) {
		watch([isGuest, () => appInit.value?.scratchCardLottery?.isActive], ([newGuest, newScratch]) => {
			if (!newGuest && newScratch) {
				handleShowPopups();
			}
		});
	}

	onMounted(() => {
		loginGuard({
			success: () => {
				handleShowPopups();
			}
		});
	});

	return {
		refresh
	};
};

export default useAppScratchCards;
