import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAppInitData from "./useAppInitData";
import useCountdown from "./useCountdown";
import useGetTournamentData from "./useGetTournamentData";
import useSubTournament from "./useSubTournament";

type NextTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/next-tournaments/", "get">;
type NextTournaments = NonNullable<NextTournamentsResponse["tournaments"]>;
type NextTournament = NonNullable<NextTournaments[number]>;
type NextWinners = NonNullable<NextTournament["winners"]>;

type PrevTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments/history/", "get">;
type PrevTournaments = NonNullable<PrevTournamentsResponse["tournaments"]>;
type PrevTournament = NonNullable<PrevTournaments[number]>;
type PrevWinners = NonNullable<PrevTournament["winners"]>;

type TournamentResponse = ExtractFromAPI<v1.paths, "/rest/tournament/", "get">;
type TournamentsData = NonNullable<TournamentResponse["data"]>;
type Winners = NonNullable<TournamentsData["winners"]>;

type ExtendWinners = NextWinners & PrevWinners & Winners;

const useRace = ({
	timerFormat,
	options = {},
	gameId = ref()
}: {
	timerFormat?: string;
	options?: { immediate?: boolean; cached?: boolean };
	gameId?: Ref<number | undefined>;
} = {}) => {
	const { data: appInitData } = useAppInitData();
	const { data: tournamentData, refresh: refreshTournamentData } = useGetTournamentData({
		gameId: toRef(() => gameId.value),
		options
	});
	const { loadingSubscribtions, handleSubscribe } = useSubTournament();

	const currentUser = computed(() => tournamentData.value?.playerData);

	const isSubscribed = computed(
		() =>
			tournamentData.value?.data?.isSubscribed ||
			!!appInitData.value?.TournamentsSubscriptions?.includes(tournamentData.value?.data?.id ?? 0)
	);

	const activeStatus = computed(() => tournamentData.value?.data?.isActive || !tournamentData.value?.data?.isOpen);

	const entriesSum = computed(() => getEntries(tournamentData.value?.data?.prizes as []));

	const coinsSum = computed(() => getCoins(tournamentData.value?.data?.prizes as []));

	const { durationLeft, reset } = useCountdown({
		timestamp: tournamentData.value?.data?.end || "",
		formatToken: timerFormat ?? "HH[ h ]mm[ m ]ss[ s ]",
		onCountdownStop: refreshTournamentData
	});

	const winners = computed(() => {
		const initialWinners = [...(tournamentData.value?.data?.winners || [])];

		const userExist = tournamentData.value?.data?.winners?.some((user) => user.profile === currentUser.value?.profile);

		const extendUser: Winners[number] = {
			name: currentUser.value?.name ?? "",
			place: currentUser.value?.place,
			points: currentUser.value?.points ?? 0,
			position: currentUser.value?.place ?? 0,
			profile: currentUser.value?.profile ?? "",
			rounds: currentUser.value?.roundsPlayed ?? 0,
			state: "",
			city: "",
			uuid: ""
		};

		return initialWinners
			.filter((user) => !!user.prizePlace)
			.map((user) => (user.profile === currentUser.value?.profile ? { ...user, ...extendUser } : user))
			.concat(userExist ? [] : [{ ...extendUser }])
			.filter((user) => user.points !== 0);
	});

	const getLeaders = (data: ExtendWinners) => {
		if (!data?.length) {
			return [];
		}
		const currentUserIndex = data.findIndex((winner) => winner.profile === currentUser.value?.profile) ?? -1;
		if (currentUserIndex <= 0) {
			return data.slice(0, 3);
		} else if (currentUserIndex === data.length - 1) {
			return data.slice(-3);
		} else {
			return data.slice(currentUserIndex - 1, currentUserIndex + 2);
		}
	};

	const leaders = computed(() => getLeaders(winners.value));

	watch(
		() => tournamentData.value?.data?.end,
		() => {
			reset(tournamentData.value?.data?.end || "");
		}
	);

	return {
		tournamentData,
		activeStatus,
		leaders,
		loadingSubscribtions,
		currentUser,
		isSubscribed,
		entriesSum,
		coinsSum,
		durationLeft,
		handleSubscribe,
		refreshTournamentData
	};
};

export default useRace;
