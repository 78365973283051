/**
 * Creates a new object by selecting keys from the input object based on a predicate function.
 *
 * @example
 * ```typescript
 * const initialObject = {
 *   name: 'Alice',
 *   age: 25,
 *   city: 'San Francisco',
 * };
 *
 * const filteredObject = pickBy(initialObject, (key, value) => key === 'name' || value === 25);
 *
 * // Result: { name: 'Alice', age: 25 }
 * ```
 */
const pickBy = <T extends object, K extends keyof T>(obj: T, predicate: (key: K, value: T[K]) => boolean) =>
	Object.entries(obj).reduce((accumulator, [key, value]) => {
		if (predicate(key as K, value)) {
			return {
				...accumulator,
				[key]: value
			};
		}
		return accumulator;
	}, {} as Partial<T>);

export default pickBy;
