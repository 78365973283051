import LazyOModalAccountChangePassword from "~/organizms/OModal/AccountChangePassword.vue";
import LazyOModalAccountEditContact from "~/organizms/OModal/AccountEditContact.vue";
import LazyOModalBundle from "~/organizms/OModal/Bundle.vue";
import LazyOModalDailyLogin from "~/organizms/OModal/DailyLogin/index.vue";
import LazyOModalDepositStreakHowItWorks from "~/organizms/OModal/DepositStreak/HowItWorks.vue";
import LazyOModalDepositStreak from "~/organizms/OModal/DepositStreak/Index.vue";
import LazyOModalDepositStreakWelcome from "~/organizms/OModal/DepositStreak/Welcome.vue";
import LazyOModalEmailConfirm from "~/organizms/OModal/EmailConfirm.vue";
import LazyOModalExit from "~/organizms/OModal/Exit.vue";
import LazyOModalFlipTheCoin from "~/organizms/OModal/FlipTheCoin.vue";
import LazyOModalFlipTheCoinUsed from "~/organizms/OModal/FlipTheCoinUsed.vue";
import LazyOModalForgotPassword from "~/organizms/OModal/ForgotPassword.vue";
import LazyOModalForgotPasswordFailed from "~/organizms/OModal/ForgotPasswordFailed.vue";
import LazyOModalForgotPasswordPhoneSent from "~/organizms/OModal/ForgotPasswordPhoneSent.vue";
import LazyOModalForgotPasswordSent from "~/organizms/OModal/ForgotPasswordSent.vue";
import LazyOModalFreeSpinAlternative from "~/organizms/OModal/FreeSpin/Alternative.vue";
import LazyOModalFreeSpinCongratulations from "~/organizms/OModal/FreeSpin/Congratulations.vue";
import LazyOModalFreeSpinFeature from "~/organizms/OModal/FreeSpin/Feature.vue";
import LazyOModalFreeSpinHowItWorks from "~/organizms/OModal/FreeSpin/HowItWorks.vue";
import LazyOModalFreeSpin from "~/organizms/OModal/FreeSpin/Index.vue";
import LazyOModalFreeSpinTaskPassed from "~/organizms/OModal/FreeSpin/TaskPassed.vue";
import LazyOModalFreeSpinWelcome from "~/organizms/OModal/FreeSpin/Welcome.vue";
import LazyOModalGameSearch from "~/organizms/OModal/Game/Search.vue";
import LazyOModalGame from "~/organizms/OModal/Game.vue";
import LazyOModalGamePreview from "~/organizms/OModal/GamePreview.vue";
import LazyOModalGamesSwitchToEntries from "~/organizms/OModal/Games/SwitchToEntries.vue";
import LazyOModalInsufficientFunds from "~/organizms/OModal/InsufficientFunds.vue";
import LazyOModalInviteFriends from "~/organizms/OModal/InviteFriends.vue";
import LazyOModalJackpotsGames from "~/organizms/OModal/JackpotsGames.vue";
import LazyOModalLogin from "~/organizms/OModal/Login.vue";
import LazyOModalMoneyBox from "~/organizms/OModal/MoneyBox.vue";
import LazyOModalMysteryBox from "~/organizms/OModal/MysteryBox.vue";
import LazyOModalMysteryBoxHowItWorks from "~/organizms/OModal/MysteryBoxHowItWorks.vue";
import LazyOModalOneClickSignup from "~/organizms/OModal/OneClickSignup.vue";
import LazyOModalOneClickSignupComplete from "~/organizms/OModal/OneClickSignupComplete.vue";
import LazyOModalPhoneConfirmation from "~/organizms/OModal/PhoneConfirmation.vue";
import LazyOModalPhoneConfirmationInfo from "~/organizms/OModal/PhoneConfirmationInfo.vue";
import LazyOModalPiggyBankBreak from "~/organizms/OModal/PiggyBank/Break.vue";
import LazyOModalPiggyBankBreakAnimation from "~/organizms/OModal/PiggyBank/BreakAnimation.vue";
import LazyOModalPresetPackage from "~/organizms/OModal/PresetPackage.vue";
import LazyOModalPrizeDropsRules from "~/organizms/OModal/PrizeDrops/Rules.vue";
import LazyOModalPrizeDropsTablePrizes from "~/organizms/OModal/PrizeDrops/TablePrizes.vue";
import LazyOModalPrizeDropsWelcome from "~/organizms/OModal/PrizeDrops/Welcome.vue";
import LazyOModalPrizeDropsWin from "~/organizms/OModal/PrizeDrops/Win.vue";
import LazyOModalPromoHowItWorks from "~/organizms/OModal/PromoHowItWorks.vue";
import LazyOModalQRCode from "~/organizms/OModal/QRCode.vue";
import LazyOModalQuestsAlmostComplete from "~/organizms/OModal/Quests/AlmostComplete.vue";
import LazyOModalQuestsCompleted from "~/organizms/OModal/Quests/Completed.vue";
import LazyOModalQuestsHowItWorks from "~/organizms/OModal/Quests/HowItWorks.vue";
import LazyOModalQuests from "~/organizms/OModal/Quests/index.vue";
import LazyOModalQuestsLevelUp from "~/organizms/OModal/Quests/LevelUp.vue";
import LazyOModalQuestsRankUp from "~/organizms/OModal/Quests/RankUp.vue";
import LazyOModalRaceHowItWorks from "~/organizms/OModal/Race/HowItWorks.vue";
import LazyOModalRaceIncreaseBet from "~/organizms/OModal/Race/IncreaseBet.vue";
import LazyOModalRaceLeaderboard from "~/organizms/OModal/Race/Leaderboard.vue";
import LazyOModalRacePrizes from "~/organizms/OModal/Race/Prizes.vue";
import LazyOModalRacesGames from "~/organizms/OModal/RacesGames.vue";
import LazyOModalRedeemHowItWork from "~/organizms/OModal/RedeemHowItWork.vue";
import LazyOModalReferralInfo from "~/organizms/OModal/Referral/ReferralInfo.vue";
import LazyOModalReferralSuccess from "~/organizms/OModal/Referral/ReferralSuccess.vue";
import LazyOModalRegistrationComplete from "~/organizms/OModal/RegistrationComplete.vue";
import LazyOModalScratchCardsFeature from "~/organizms/OModal/ScratchCards/Feature.vue";
import LazyOModalScratchCardsPlayForWin from "~/organizms/OModal/ScratchCards/PlayForWin.vue";
import LazyOModalScratchCardsWelcome from "~/organizms/OModal/ScratchCards/Welcome.vue";
import LazyOModalScratchCardsYouWon from "~/organizms/OModal/ScratchCards/YouWon.vue";
import LazyOModalSeasonFishingGames from "~/organizms/OModal/Season/FishingGames.vue";
import LazyOModalSeasonGames from "~/organizms/OModal/Season/Games.vue";
import LazyOModalSeason from "~/organizms/OModal/Season/index.vue";
import LazyOModalSeasonLeaveSeason from "~/organizms/OModal/Season/LeaveSeason.vue";
import LazyOModalSeasonRules from "~/organizms/OModal/Season/Rules.vue";
import LazyOModalSeasonVipClubPaused from "~/organizms/OModal/Season/VipClubPaused.vue";
import LazyOModalSeasonWelcome from "~/organizms/OModal/Season/Welcome.vue";
import LazyOModalSignup from "~/organizms/OModal/Signup.vue";
import LazyOModalSignupExitPopup from "~/organizms/OModal/SignupExitPopup.vue";
import LazyOModalTermsDialog from "~/organizms/OModal/TermsDialog.vue";
import LazyOModalVipClubGames from "~/organizms/OModal/VipClub/Games.vue";
import LazyOModalWelcome from "~/organizms/OModal/Welcome.vue";
import LazyOModalWheelFortuneWheel from "~/organizms/OModal/Wheel/FortuneWheel.vue";
import LazyOModalWheelHowItWorks from "~/organizms/OModal/Wheel/HowItWorks.vue";

export type Modals = {
	LazyOModalForgotPassword: InstanceType<typeof LazyOModalForgotPassword>["$props"];
	LazyOModalForgotPasswordSent: InstanceType<typeof LazyOModalForgotPasswordSent>["$props"];
	LazyOModalLogin: InstanceType<typeof LazyOModalLogin>["$props"];
	LazyOModalSignup: InstanceType<typeof LazyOModalSignup>["$props"];
	LazyOModalOneClickSignup: InstanceType<typeof LazyOModalOneClickSignup>["$props"];
	LazyOModalOneClickSignupComplete: InstanceType<typeof LazyOModalOneClickSignupComplete>["$props"];
	LazyOModalSignupExitPopup: InstanceType<typeof LazyOModalSignupExitPopup>["$props"];
	LazyOModalEmailConfirm: InstanceType<typeof LazyOModalEmailConfirm>["$props"];
	LazyOModalExit: InstanceType<typeof LazyOModalExit>["$props"];
	LazyOModalAccountEditContact: InstanceType<typeof LazyOModalAccountEditContact>["$props"];
	LazyOModalAccountChangePassword: InstanceType<typeof LazyOModalAccountChangePassword>["$props"];
	LazyOModalPhoneConfirmation: InstanceType<typeof LazyOModalPhoneConfirmation>["$props"];
	LazyOModalPhoneConfirmationInfo: InstanceType<typeof LazyOModalPhoneConfirmationInfo>["$props"];
	LazyOModalRacesGames: InstanceType<typeof LazyOModalRacesGames>["$props"];
	LazyOModalRedeemHowItWork: InstanceType<typeof LazyOModalRedeemHowItWork>["$props"];
	LazyOModalPiggyBankBreakAnimation: InstanceType<typeof LazyOModalPiggyBankBreakAnimation>["$props"];
	LazyOModalPiggyBankBreak: InstanceType<typeof LazyOModalPiggyBankBreak>["$props"];
	LazyOModalRegistrationComplete: InstanceType<typeof LazyOModalRegistrationComplete>["$props"];
	LazyOModalTermsDialog: InstanceType<typeof LazyOModalTermsDialog>["$props"];
	LazyOModalGame: InstanceType<typeof LazyOModalGame>["$props"];
	LazyOModalInsufficientFunds: InstanceType<typeof LazyOModalInsufficientFunds>["$props"];
	LazyOModalPromoHowItWorks: InstanceType<typeof LazyOModalPromoHowItWorks>["$props"];
	LazyOModalInviteFriends: InstanceType<typeof LazyOModalInviteFriends>["$props"];
	LazyOModalPresetPackage: InstanceType<typeof LazyOModalPresetPackage>["$props"];
	LazyOModalWelcome: InstanceType<typeof LazyOModalWelcome>["$props"];
	LazyOModalFlipTheCoin: InstanceType<typeof LazyOModalFlipTheCoin>["$props"];
	LazyOModalFlipTheCoinUsed: InstanceType<typeof LazyOModalFlipTheCoinUsed>["$props"];
	LazyOModalJackpotsGames: InstanceType<typeof LazyOModalJackpotsGames>["$props"];
	LazyOModalMoneyBox: InstanceType<typeof LazyOModalMoneyBox>["$props"];
	LazyOModalMysteryBox: InstanceType<typeof LazyOModalMysteryBox>["$props"];
	LazyOModalQuests: InstanceType<typeof LazyOModalQuests>["$props"];
	LazyOModalQuestsHowItWorks: InstanceType<typeof LazyOModalQuestsHowItWorks>["$props"];
	LazyOModalQuestsAlmostComplete: InstanceType<typeof LazyOModalQuestsAlmostComplete>["$props"];
	LazyOModalQuestsCompleted: InstanceType<typeof LazyOModalQuestsCompleted>["$props"];
	LazyOModalQuestsLevelUp: InstanceType<typeof LazyOModalQuestsLevelUp>["$props"];
	LazyOModalQuestsRankUp: InstanceType<typeof LazyOModalQuestsRankUp>["$props"];
	LazyOModalWheelFortuneWheel: InstanceType<typeof LazyOModalWheelFortuneWheel>["$props"];
	LazyOModalWheelHowItWorks: InstanceType<typeof LazyOModalWheelHowItWorks>["$props"];
	LazyOModalGamesSwitchToEntries: InstanceType<typeof LazyOModalGamesSwitchToEntries>["$props"];
	LazyOModalVipClubGames: InstanceType<typeof LazyOModalVipClubGames>["$props"];
	LazyOModalRacePrizes: InstanceType<typeof LazyOModalRacePrizes>["$props"];
	LazyOModalRaceLeaderboard: InstanceType<typeof LazyOModalRaceLeaderboard>["$props"];
	LazyOModalRaceIncreaseBet: InstanceType<typeof LazyOModalRaceIncreaseBet>["$props"];
	LazyOModalRaceHowItWorks: InstanceType<typeof LazyOModalRaceHowItWorks>["$props"];
	LazyOModalGamePreview: InstanceType<typeof LazyOModalGamePreview>["$props"];
	LazyOModalForgotPasswordFailed: InstanceType<typeof LazyOModalForgotPasswordFailed>["$props"];
	LazyOModalForgotPasswordPhoneSent: InstanceType<typeof LazyOModalForgotPasswordPhoneSent>["$props"];
	LazyOModalScratchCardsPlayForWin: InstanceType<typeof LazyOModalScratchCardsPlayForWin>["$props"];
	LazyOModalMysteryBoxHowItWorks: InstanceType<typeof LazyOModalMysteryBoxHowItWorks>["$props"];
	LazyOModalDailyLogin: InstanceType<typeof LazyOModalDailyLogin>["$props"];
	LazyOModalSeasonWelcome: InstanceType<typeof LazyOModalSeasonWelcome>["$props"];
	LazyOModalSeasonRules: InstanceType<typeof LazyOModalSeasonRules>["$props"];
	LazyOModalSeasonLeaveSeason: InstanceType<typeof LazyOModalSeasonLeaveSeason>["$props"];
	LazyOModalSeasonGames: InstanceType<typeof LazyOModalSeasonGames>["$props"];
	LazyOModalSeason: InstanceType<typeof LazyOModalSeason>["$props"];
	LazyOModalSeasonVipClubPaused: InstanceType<typeof LazyOModalSeasonVipClubPaused>["$props"];
	LazyOModalScratchCardsYouWon: InstanceType<typeof LazyOModalScratchCardsYouWon>["$props"];
	LazyOModalScratchCardsWelcome: InstanceType<typeof LazyOModalScratchCardsWelcome>["$props"];
	LazyOModalScratchCardsFeature: InstanceType<typeof LazyOModalScratchCardsFeature>["$props"];
	LazyOModalSeasonFishingGames: InstanceType<typeof LazyOModalSeasonFishingGames>["$props"];
	LazyOModalPrizeDropsWelcome: InstanceType<typeof LazyOModalPrizeDropsWelcome>["$props"];
	LazyOModalPrizeDropsRules: InstanceType<typeof LazyOModalPrizeDropsRules>["$props"];
	LazyOModalPrizeDropsWin: InstanceType<typeof LazyOModalPrizeDropsWin>["$props"];
	LazyOModalPrizeDropsTablePrizes: InstanceType<typeof LazyOModalPrizeDropsTablePrizes>["$props"];
	LazyOModalDepositStreakHowItWorks: InstanceType<typeof LazyOModalDepositStreakHowItWorks>["$props"];
	LazyOModalDepositStreakWelcome: InstanceType<typeof LazyOModalDepositStreakWelcome>["$props"];
	LazyOModalDepositStreak: InstanceType<typeof LazyOModalDepositStreak>["$props"];
	LazyOModalReferralInfo: InstanceType<typeof LazyOModalReferralInfo>["$props"];
	LazyOModalReferralSuccess: InstanceType<typeof LazyOModalReferralSuccess>["$props"];
	LazyOModalQRCode: InstanceType<typeof LazyOModalQRCode>["$props"];
	LazyOModalFreeSpinWelcome: InstanceType<typeof LazyOModalFreeSpinWelcome>["$props"];
	LazyOModalFreeSpinHowItWorks: InstanceType<typeof LazyOModalFreeSpinHowItWorks>["$props"];
	LazyOModalFreeSpin: InstanceType<typeof LazyOModalFreeSpin>["$props"];
	LazyOModalFreeSpinCongratulations: InstanceType<typeof LazyOModalFreeSpinCongratulations>["$props"];
	LazyOModalFreeSpinAlternative: InstanceType<typeof LazyOModalFreeSpinAlternative>["$props"];
	LazyOModalFreeSpinFeature: InstanceType<typeof LazyOModalFreeSpinFeature>["$props"];
	LazyOModalFreeSpinTaskPassed: InstanceType<typeof LazyOModalFreeSpinTaskPassed>["$props"];
	LazyOModalGameSearch: InstanceType<typeof LazyOModalGameSearch>["$props"];
	LazyOModalBundle: InstanceType<typeof LazyOModalBundle>["$props"];
};

const useAppModals = () => useModals<Modals>();

export default useAppModals;
