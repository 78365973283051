import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";

export type InitDataResponse = ExtractFromAPI<v1.paths, "/rest/app/init/", "get">;
export type InitDataResponseData = InitDataResponse["data"];

const useAppInitData = ({ immediate }: { immediate?: boolean } = {}) => {
	const asyncData = useAsyncFetch({
		path: "/rest/app/init/",
		method: "get",
		options: {
			immediate,
			cached: true,
			transform: (response) => response.data
		}
	});

	const favoritesSet = computed(() => new Set(asyncData.data.value?.favoriteGames));
	return {
		...asyncData,
		favoritesSet
	};
};

export default useAppInitData;
