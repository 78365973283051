const useHomePage = ({ immediate }: { immediate?: boolean } = {}) => {
	const { data: appInit } = useAppInitData();
	const { isLimitOffer } = useFlipTheCoin();
	const { tournamentData } = useRace();
	const { isFreeSpinQuest } = useFreeSpinQuest();
	const { select } = useGamesState();
	const isGuest = useIsGuest();

	const { data: pageData, pending: loading, refresh } = useHomeData({ immediate, cached: true, server: true });

	const { data: recentWinners } = useAsyncFetch({
		path: "/rest/players-activity/",
		method: "get",
		fetchOptions: {
			query: { limit: 6 }
		},
		options: {
			cached: true
		}
	});

	const { games: allGamesResponse } = useGetGameFiltersData({ immediate });

	const makeTwoRowsTable = <T>(arr: T[]): T[][] =>
		arr.reduce((result, current, index, array) => {
			if (index % 2 === 0) {
				result.push(array.slice(index, index + 2) as T[]);
			}
			return result;
		}, [] as T[][]);

	const seoData = computed(() => pageData.value?.seo);
	const jackpots = computed(() => pageData.value?.jackpots);
	const promotions = computed(() => pageData.value?.promotions || []);
	const lastWinners = computed(() => pageData.value?.lastWinners || null);
	const packages = computed(() => pageData.value?.packages);

	const bundleOffer = computed(() => packages.value?.promoOffers?.bundle);

	const lastGames = computed(() => select(appInit.value?.lastGames || []));
	const favoritesSet = computed(() => new Set(appInit.value?.favoriteGames || []));

	const jackpotGames = computed(() => select(pageData?.value?.games?.jackpot || []));
	const holdNLinksGames = computed(() => select(pageData?.value?.games?.holdNLink || []));
	const popularGames = computed(() => select(pageData?.value?.games?.popularGames || []));
	const hotGames = computed(() => select(pageData?.value?.games?.hotGames || []));
	const allGamesIssues = computed(() => allGamesResponse.value);
	const gameOfWeek = computed(() => select(pageData.value?.gameOfWeek || [])?.[0]);
	const newGames = computed(() => select(pageData?.value?.games?.newGames || []));

	const allGames = computed(() => {
		const gamesArray = allGamesResponse.value || [];
		return gamesArray.map((game) => ({
			...game,
			isFavorite: game.id ? favoritesSet.value.has(game.id) : false
		}));
	});

	const yourPick = computed(() => {
		const reversedFavGames = [...(appInit.value?.favoriteGames || "")].reverse();
		const uniqueGames = [...new Set([...(appInit.value?.lastGames || []), ...reversedFavGames])];
		setTimeout(() => {
			refresh();
		}, 1000);

		return uniqueGames.map((id) => allGames.value?.find((g: { id?: number }) => g.id === id)).filter(Boolean);
	});

	const holdNLinksGamesArrayable = computed(() => makeTwoRowsTable(holdNLinksGames.value));
	const yourPickGamesArrayable = computed(() => makeTwoRowsTable(yourPick.value));
	const jackpotGamesArrayable = computed(() => makeTwoRowsTable(jackpotGames.value));

	const questData = computed(() => pageData.value?.questData);

	const filtredBanners = computed(
		() =>
			pageData.value?.banners?.filter(({ type }) => {
				if (type === "freeSpin") {
					return !!isFreeSpinQuest.value;
				}

				if (type === "quest") {
					return !!questData.value?.quest;
				}

				if (type === "raceBanner") {
					return !!tournamentData.value?.isActiveStatus;
				}

				if (type === "scratchBanner") {
					return !isGuest.value && !!appInit.value?.scratchCardLottery?.isActive;
				}

				return type !== "flipTheCoin" || !isLimitOffer.value;
			}) || []
	);

	const banners = computed(() => {
		if (!appInit.value?.isGuest && appInit.value?.bannerPresetPackage) {
			return [
				{
					type: "bannerPresetPackage",
					...appInit.value.bannerPresetPackage
				},
				...(filtredBanners.value || [])
			];
		}
		return filtredBanners.value || [];
	});

	return {
		loading,
		pageData,
		packages,
		allGames,
		allGamesIssues,
		lastGames,
		refresh,
		jackpotGames,
		holdNLinksGames,
		holdNLinksGamesArrayable,
		yourPickGamesArrayable,
		jackpotGamesArrayable,
		promotions,
		gameOfWeek,
		popularGames,
		hotGames,
		yourPick,
		banners,
		recentWinners,
		questData,
		lastWinners,
		seoData,
		jackpots,
		newGames,
		bundleOffer
	};
};

export default useHomePage;
