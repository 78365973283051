/**
 * Creates a new object by selecting specified keys from the input object.
 *
 * @example
 * const initialObject = {
 *   name: 'Alice',
 *   age: 25,
 *   city: 'San Francisco',
 * };
 *
 * const pickedObject = pick(initialObject, ['name', 'city']);
 *
 * // Result: { name: 'Alice', city: 'San Francisco' }
 */
const pick = <T extends object, K extends keyof T>(obj: T, keys: K[]) =>
	keys.reduce(
		(accumulator, key) => {
			if (key in obj) {
				return {
					...accumulator,
					[key]: obj[key]
				};
			}

			return accumulator;
		},
		{} as Pick<T, K>
	);

export default pick;
