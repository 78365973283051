const initialState = {
	referralPopup: {
		stepsToShow: 0
	},
	offerPopup: {
		stepsToShow: 0
	},
	loginAfterSocialReg: false,
	loginBySocial: false,
	magicBox: false,
	isLoginAfterRegistration: false,
	isLoginFlow: false,
	initPopupState: false
};

const usePopupsState = () => {
	const state = useState("popupState", () => initialState);

	const reset = () => {
		state.value = initialState;
	};

	return {
		state,
		reset
	};
};

export default usePopupsState;
