import type { AsyncDataOptions } from "#app";

import { apiClient, type v1, type ExtractFromAPI } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useHomeData from "./useHomeData";
import useIsGuest from "./useIsGuest";

type PrizeDropsResponse = Required<ExtractFromAPI<v1.paths, "/rest/daily-login/info/", "get">>;

const defaultData = {
	finishedAt: "",
	isActive: false,
	isAvailable: false,
	levels: 7,
	activeLevel: 1,
	prizes: [{ coins: 0, entries: 0, freeSpinCount: 0, gameId: 0 }]
};

const useDailyLoginData = ({
	immediate = true,
	cached = true,
	...restOptions
}: AsyncDataOptions<PrizeDropsResponse> & { cached?: true } = {}) => {
	const isGuest = useIsGuest();
	const { refresh: refreshHomePageData } = useHomeData({ immediate: false });
	const { refresh: refreshPromotionsPageData } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			immediate: false
		}
	});

	const grandPrize = ref({ coins: 0, entries: 0, freeSpinCount: 0, gameId: 0 });

	const data = useAsyncFetch({
		path: "/rest/daily-login/info/",
		method: "get",
		options: {
			cached: !cached ? undefined : true,
			server: restOptions?.server,
			immediate: immediate && !isGuest.value,
			watch: [...(restOptions?.watch || [])],
			default: () => defaultData
		}
	});

	const handleApplyPrize = async () =>
		await apiClient({
			path: "/rest/daily-login/apply-prize/",
			method: "get",
			options: {
				onResponse: ({ response }) => {
					if (!response?._data?.success) {
						return;
					}

					grandPrize.value = response?._data?.prizes;

					if (response?._data?.prizes?.freeSpinCount) {
						refreshHomePageData();
						refreshPromotionsPageData();
					}
				}
			}
		});

	return { ...data, grandPrize, handleApplyPrize };
};

export default useDailyLoginData;
