const useMysteryBoxInit = () => {
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const { open } = useAppModals();
	const { playMysteryBoxAudio } = useMysteryBoxAudios();
	const { isActiveDailyLogin } = useDailyLogin();

	onMounted(() => {
		loginGuard({
			success() {
				if (!route?.query?.openModal) {
					return;
				}
				if (route.query.openModal === "magic-box") {
					if (isActiveDailyLogin.value) {
						open("LazyOModalDailyLogin");
						return;
					}

					open("LazyOModalMysteryBox");
					playMysteryBoxAudio();
				}
			}
		});
	});
};

export default useMysteryBoxInit;
