const useWelcomePopup = () => {
	const { open } = useAppModals();
	const { data: appData } = useAppInitData();
	const route = useRoute();
	const loginGuard = useLoginGuard();

	const showAfterMailConfirm = computed(
		() => !!appData.value?.emailConfirmed && route?.query?.openNotify === "EMAIL_VERIFIED"
	);

	const handleShowWelcomePopup = () => {
		if (showAfterMailConfirm.value) {
			open("LazyOModalWelcome");
		}
	};

	watch([() => appData.value?.emailConfirmed, () => route.query?.openNotify], () => {
		handleShowWelcomePopup();
	});

	onMounted(() => {
		loginGuard({
			success: () => {
				handleShowWelcomePopup();
			}
		});
	});
};

export default useWelcomePopup;
