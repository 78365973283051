const usePresetPackageInit = () => {
	const { data } = useAppInitData();
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const guardFunction = usePopupPresetPackageGuard();
	const { state } = usePopupsState();
	const { openDefault, openAuth, openLoginSocial } = usePresetPackageController();

	const hasDirectLink = (emit = false): boolean => {
		const { query, hash } = route;
		return !!query.game || (hash.includes("/cash") && !emit) || !!query.openSupport;
	};

	watch(
		() => data.value?.isGuest,
		(value, oldValue) => {
			if (!value && oldValue) {
				openLoginSocial();
			}
		}
	);

	watch(
		() => route.query,
		(value, oldValue) => {
			loginGuard({
				success: () => {
					if (!!oldValue?.social || !!oldValue?.action) {
						if (oldValue.social === "login") {
							openDefault();
						}
					}
				}
			});
		}
	);

	const initPopup = (emit = false) => {
		const redirectToPackagePopup = route?.query?.redirectUrl === "bannerPresetPackage";
		const openModalPreset = route?.query?.openModal === "app-banner-preset-package-modal";

		openLoginSocial();

		if (!hasDirectLink(emit) && !state.value.initPopupState) {
			state.value.initPopupState = true;
		}

		if (redirectToPackagePopup) {
			openAuth();
			return;
		}

		if (openModalPreset) {
			openDefault();
		}
	};

	onMounted(() => {
		guardFunction({
			success: () => {
				if (route?.path?.includes("game") || route?.query?.game || route?.hash?.includes("cash")) {
					return;
				}

				initPopup();
			}
		});
	});
};

export default usePresetPackageInit;
