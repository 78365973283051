import type { EventData, InitDataResponseData, PromoData } from "@/types";

type InitDataRankLeague = NonNullable<InitDataResponseData>["rankLeague"];
type InitDataProgressData = NonNullable<InitDataRankLeague>["progressData"];
type RankLeagueSocketData = {
	sectionType: StatusSystemType;
	progressData: InitDataProgressData;
	currentLevel: number;
	type: "wheel" | "promoOfferPreset" | "coinsEntries";
	promoOfferPreset?: PromoData;
	prize?: {
		entries: number | null;
		coins: number | null;
	};
};

export type StatusSystemType =
	| "rookie"
	| "gamer"
	| "voyager"
	| "showstopper"
	| "mogul"
	| "highroller"
	| "headliner"
	| "baron"
	| "legend"
	| "overlord";

const useRankedLeagueSockets = () => {
	const { data } = useAppInitData();
	const { type } = useRankedSystem();
	const { add, remove } = useSockets();
	const { open, close, isOpen } = useAppModals();
	const { refresh: refreshLuckySpinData } = useGetLuckySpinData({ immediate: false });
	const { refresh: refreshRankedWheelsData } = useGetRankedWheelsData({ immediate: false });
	const { exitFullscreen } = useFullscreenToggle();

	add("rankLeague", (event: EventData<RankLeagueSocketData>) => {
		if (event.action === "sectionUp") {
			if (isOpen("LazyOModalQuestsLevelUp")) {
				close("LazyOModalQuestsLevelUp");
			}
			open("LazyOModalQuestsRankUp", { wheelType: event.data.sectionType, prize: event.data.prize });
			exitFullscreen();
			refreshRankedWheelsData();
		}
		if (event.action === "levelUp") {
			if (isOpen("LazyOModalQuestsRankUp")) {
				return;
			}
			if (!isOpen("LazyOModalGame")) {
				return;
			}
			setTimeout(() => {
				open("LazyOModalQuestsLevelUp", { status: type.value, level: event.data.currentLevel });
				exitFullscreen();
				dispatchGAEvent({
					event: "open_popup",
					quantity: event.data.currentLevel,
					form_name: "level_up",
					location: "reward"
				});
			}, 1000);
		}
		if (event.action === "assignPrize") {
			const level = data.value?.rankLeague?.progressData?.section.level ?? 0;

			if (event.data.type === "coinsEntries") {
				dispatchGAEvent({
					event: "open_popup",
					form_name: "coins_entries",
					quantity: level,
					location: "level_up"
				});
			}

			if (event.data.type === "wheel") {
				refreshLuckySpinData();
				dispatchGAEvent({
					event: "open_popup",
					form_name: "lucky_spin",
					quantity: level,
					location: "level_up"
				});
			}
		}
		if (event.action === "progressData" && data.value?.rankLeague?.progressData) {
			data.value.rankLeague.progressData = { ...data.value.rankLeague.progressData, ...event.data.progressData };
		}
	});

	onUnmounted(() => {
		remove("rankLeague");
	});
};

export default useRankedLeagueSockets;
