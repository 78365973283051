const secondCountToShow = 2;

const usePopupsInit = () => {
	const { data: appData } = useAppInitData();
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const { openDefault: openPresetPackagesPopup } = usePresetPackageController();
	const { state: popupState, reset: resetPopupState } = usePopupsState();
	const { handleAsyncOpen: handleAsyncMysteryBoxesOpen } = useMysteryBoxesController();

	watch(
		() => appData.value?.isGuest,
		(value) => {
			if (value) {
				resetPopupState();
			}
			popupState.value.isLoginAfterRegistration = !popupState.value.isLoginFlow;
		}
	);

	watch([() => route.path, () => route.query], ([newPath], [oldPath, oldQuery]) => {
		loginGuard({
			success: () => {
				if (!!oldQuery?.social || !!oldQuery?.action) {
					popupState.value.loginAfterSocialReg = oldQuery.action === "register";
					popupState.value.loginBySocial = oldQuery.action === "login";
					dispatchGAEvent({
						event: popupState.value.isLoginFlow ? "login" : "registration",
						location: "header",
						type: appData.value?.activeSocialAccounts?.[0] || "email",
						step: "success",
						playerId: appData.value?.login || ""
					});
				}
				if (newPath === "/" && oldPath !== "/") {
					if (!popupState.value.isLoginAfterRegistration) {
						if (popupState.value.loginAfterSocialReg || popupState.value.loginBySocial) {
							popupState.value.offerPopup.stepsToShow += 1;
							if (popupState.value.offerPopup.stepsToShow >= secondCountToShow) {
								popupState.value.magicBox = true;
								popupState.value.loginAfterSocialReg = false;
								return;
							}
							handleAsyncMysteryBoxesOpen();
						}
						return;
					}
					if (!popupState.value.magicBox) {
						popupState.value.offerPopup.stepsToShow += 1;
						if (popupState.value.offerPopup.stepsToShow === secondCountToShow) {
							if (!popupState.value.isLoginFlow) {
								openPresetPackagesPopup();
								return;
							}
							if (!appData.value?.depositsSum) {
								return;
							}
						}
						if (popupState.value.offerPopup.stepsToShow >= secondCountToShow) {
							popupState.value.magicBox = true;
							return;
						}
						handleAsyncMysteryBoxesOpen();
					}
				}
			}
		});
	});

	onMounted(() => {
		if (route.query?.action || route.query?.social) {
			if (route.query.action === "register") {
				popupState.value.loginAfterSocialReg = true;
			}
			dispatchGAEvent({
				event: route.query.action === "register" ? "registration" : "login",
				location: "header",
				type: route.query.social as string,
				step: "success",
				playerId: appData.value?.login || ""
			});
		}
	});
};

export default usePopupsInit;
