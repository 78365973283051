/* eslint-disable indent */
export type GuardProps = {
	fail?: () => void;
	success?: () => void;
	condition?: () => boolean;
};

const createGuard =
	({ condition: deafultCondition, fail: defaultFail, success: defaultSuccess }: GuardProps) =>
	({ condition: caseCondition, fail: caseFail, success: caseSuccess }: GuardProps = {}) => {
		const condition = caseCondition || deafultCondition;
		const fail = caseFail || defaultFail;
		const success = caseSuccess || defaultSuccess;
		if (condition?.()) {
			success?.();
			return;
		}
		fail?.();
	};

export default createGuard;
