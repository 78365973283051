import useAppInitData from "./useAppInitData";
import useGetRankedLeagueInfo from "./useGetRankedLeagueInfo";

type StatusSystemTypeFunrize = "bronze" | "silver" | "gold" | "platinum" | "sapphire" | "diamond" | "vip";

type StatusSystemTypeFunzcity =
	| "rookie"
	| "gamer"
	| "voyager"
	| "showstopper"
	| "mogul"
	| "highroller"
	| "headliner"
	| "baron"
	| "legend"
	| "overlord";

type StatusSystemInitialType = "bronze" | "rookie";

type StatusSystemTypeExtended = StatusSystemTypeFunrize | StatusSystemTypeFunzcity;

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;

const STATUS_SYSTEM_TYPES = new Map<StatusSystemTypeExtended, StatusSystemTypeExtended>([
	["bronze", "silver"],
	["silver", "gold"],
	["gold", "platinum"],
	["platinum", "sapphire"],
	["sapphire", "diamond"],
	["diamond", "vip"],
	["vip", "vip"]
]);
const MAX_LEVEL_STATUS_SYSTEM = 70;

const useRankedSystem = <K extends string, P>({
	levelsRange = 8,
	initialType = "rookie",
	initialNextType = "silver",
	open = () => {}
}: {
	levelsRange?: number;
	initialNextType?: StatusSystemTypeExtended;
	initialType?: StatusSystemInitialType;
	open?: ModalOpen<K, P>;
} = {}) => {
	const { data: appInitData } = useAppInitData();
	const { data: rankedSystemData, refresh: refreshRankedSystemData } = useGetRankedLeagueInfo();
	const guardResult = createGuard({ condition: () => !!rankedSystemData.value?.available });

	const rankLeague = computed(() => appInitData.value?.rankLeague);
	const pointsReceived = computed(() => rankLeague.value?.progressData?.level?.pointsReceived);
	const pointsNeedForNextLevel = computed(() => {
		if (
			typeof rankLeague.value?.progressData?.level?.pointsNeedForNextLevel === "number" &&
			typeof pointsReceived.value === "number"
		) {
			return rankLeague.value?.progressData?.level?.pointsNeedForNextLevel + pointsReceived.value;
		}
		return 0;
	});
	const level = computed(() => rankLeague.value?.progressData?.section?.level || 0);
	const nextLevel = computed(() => {
		if (!rankLeague.value) {
			return 2;
		}

		return level.value === MAX_LEVEL_STATUS_SYSTEM ? MAX_LEVEL_STATUS_SYSTEM : level.value + 1;
	});
	const levelMax = computed(() => rankLeague.value?.progressData?.section?.levelMax);
	const type = computed(() => rankLeague.value?.progressData?.section?.type || initialType);
	const isConfirmedProfile = computed(
		() => appInitData.value?.emailConfirmed && appInitData.value?.profileCompleted && appInitData.value?.phoneConfirmed
	);
	const enabled = computed(() => rankLeague.value && !(!rankLeague.value?.enabled && isConfirmedProfile.value));
	const nextType = STATUS_SYSTEM_TYPES.get(type.value) || initialNextType;
	const rankLeagueEnabled = computed(() => rankLeague.value?.enabled);
	const pointsLeftForNextLevel = computed(() => rankLeague.value?.progressData?.level?.pointsNeedForNextLevel || 0);
	const rankLeagueBlocked = computed(() => appInitData.value?.rankLeague?.progressData?.status === "blocked");
	const derankedInfo = computed(() => {
		if (rankLeagueBlocked.value) {
			return false;
		}
		return !!rankLeague.value?.resetNotificationDays && rankLeague.value?.resetNotificationDays !== null;
	});
	const derankedDaysLeft = computed(() => rankLeague.value?.resetNotificationDays || 0);
	const calculateLevel = computed(() => ((level.value - 1) % levelsRange) + 1);

	const handleUnlock = (cb = () => {}) => {
		guardResult({
			fail() {
				if (rankedSystemData.value?.reasonType === "profile" || rankedSystemData.value?.reasonType === "easyProfile") {
					window?.$cash?.$router?.push("/cash/account/?isOutsideCash=true");
				}
				if (rankedSystemData.value?.reasonType === "email") {
					open("LazyOModalEmailConfirm" as K);
				}
				if (rankedSystemData.value?.reasonType === "phone") {
					open("LazyOModalPhoneConfirmation" as K, { goToSecondStep: !!appInitData.value?.phone } as P);
				}
			},
			success() {
				cb();
			}
		});
	};

	return {
		rankLeague,
		rankLeagueBlocked,
		derankedDaysLeft,
		derankedInfo,
		pointsNeedForNextLevel,
		nextLevel,
		nextType,
		enabled,
		levelsRange,
		rankLeagueEnabled,
		isConfirmedProfile,
		appInitData,
		pointsLeftForNextLevel,
		pointsReceived,
		levelMax,
		level,
		type,
		calculateLevel,
		refreshRankedSystemData,
		handleUnlock
	};
};

export default useRankedSystem;
