const parseJSON = <T = undefined | Record<string | number, string | number | boolean | undefined | null>>(
	json: string
): T => {
	try {
		return JSON.parse(json);
	} catch (e) {
		return undefined as T;
	}
};

export default parseJSON;
