import useHasPopupPresetPackage from "./useHasPopupPresetPackage";
import useIsGuest from "./useIsGuest";

const usePopupPresetPackageGuard = () => {
	const isGuest = useIsGuest();
	const hasPopupPresetPackage = useHasPopupPresetPackage();

	return createGuard({ condition: () => !isGuest.value && hasPopupPresetPackage() });
};

export default usePopupPresetPackageGuard;
