type Options = Partial<{ locale: string; minimumFractionDigits: number; maximumFractionDigits: number }>;

const numberFormat = (
	value: number,
	{ locale, maximumFractionDigits, minimumFractionDigits }: Options = {
		locale: "en-US",
		minimumFractionDigits: 0,
		maximumFractionDigits: 2
	}
) =>
	value.toLocaleString(locale, {
		minimumFractionDigits,
		maximumFractionDigits
	});

export default numberFormat;
