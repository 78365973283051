/* eslint-disable @typescript-eslint/no-explicit-any */
import type { NuxtApp } from "nuxt/app";
import type { Ref, WatchSource } from "vue";

export type AsyncDataRequestStatus = "idle" | "pending" | "success" | "error";

export type _Transform<Input = any, Output = any> = (input: Input) => Output;

export type PickFrom<T, K extends Array<string>> =
	T extends Array<any>
		? T
		: T extends Record<string, any>
			? keyof T extends K[number]
				? T // Exact same keys as the target, skip Pick
				: K[number] extends never
					? T
					: Pick<T, K[number]>
			: T;

export type KeysOf<T> = Array<
	T extends T // Include all keys of union types, not just common keys
		? keyof T extends string
			? keyof T
			: never
		: never
>;

export type KeyOfRes<Transform extends _Transform> = KeysOf<ReturnType<Transform>>;

export type MultiWatchSources = (WatchSource<unknown> | object)[];

export interface AsyncDataOptions<ResT, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null> {
	server?: boolean;
	lazy?: boolean;
	default?: () => DefaultT | Ref<DefaultT>;
	getCachedData?: (key: string) => DataT;
	transform?: _Transform<ResT, DataT>;
	pick?: PickKeys;
	watch?: MultiWatchSources;
	immediate?: boolean;
}

export interface AsyncDataExecuteOptions {
	_initial?: boolean;
	/**
	 * Force a refresh, even if there is already a pending request. Previous requests will
	 * not be cancelled, but their result will not affect the data/pending state - and any
	 * previously awaited promises will not resolve until this new request resolves.
	 */
	dedupe?: boolean;
}

export interface _AsyncData<DataT, ErrorT> {
	data: Ref<DataT>;
	pending: Ref<boolean>;
	refresh: (opts?: AsyncDataExecuteOptions) => Promise<void>;
	execute: (opts?: AsyncDataExecuteOptions) => Promise<void>;
	error: Ref<ErrorT | null>;
	status: Ref<AsyncDataRequestStatus>;
	isLoading: Ref<boolean>;
}

export type AsyncData<Data, Error> = _AsyncData<Data, Error> & Promise<_AsyncData<Data, Error>>;

const getDefault = () => null;

const useAsync = <ResT, DataE = Error, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
	key: string,
	handler: (ctx?: NuxtApp) => Promise<ResT>,
	options: AsyncDataOptions<ResT, DataT, PickKeys, DefaultT> & { cached?: true } = {}
) => {
	const nuxt = useNuxtApp();
	const getDefaultCachedData = () =>
		nuxt.isHydrating || options.cached === true ? nuxt.payload.data[key] : nuxt.static.data[key];

	// Apply defaults
	options.server = options.server ?? true;
	options.default = options.default ?? (getDefault as () => DefaultT);
	options.getCachedData = options.getCachedData ?? getDefaultCachedData;

	options.lazy = options.lazy ?? false;
	options.immediate = options.immediate ?? true;

	const hasCachedData = () => options.getCachedData!(key) !== undefined;

	// Create or use a shared asyncData entity
	if (!nuxt._asyncData[key]) {
		nuxt._asyncData[key] = {
			data: ref(options.getCachedData(key) ?? options.default!()),
			pending: ref(!hasCachedData()),
			error: toRef(nuxt.payload._errors, key),
			status: ref("idle"),
			isLoading: ref(false)
		} as any;
	}

	// TODO: Else, somehow check for conflicting keys with different defaults or fetcher
	const asyncData = { ...nuxt._asyncData[key] } as AsyncData<DataT | DefaultT, DataE>;

	asyncData.refresh = asyncData.execute = (opts = {}) => {
		if (nuxt._asyncDataPromises[key]) {
			if (opts.dedupe === false) {
				// Avoid fetching same key more than once at a time
				return nuxt._asyncDataPromises[key] as Promise<any>;
			}
			(nuxt._asyncDataPromises[key] as unknown as { cancelled: boolean }).cancelled = true;
		}
		// Avoid fetching same key that is already fetched
		if ((opts._initial || (nuxt.isHydrating && opts._initial !== false)) && hasCachedData()) {
			return Promise.resolve(options.getCachedData!(key));
		}
		asyncData.pending.value = true;
		asyncData.status.value = "pending";
		asyncData.isLoading.value = true;
		// TODO: Cancel previous promise
		const promise = new Promise<ResT>((resolve, reject) => {
			try {
				resolve(handler(nuxt));
			} catch (err) {
				reject(err);
			}
		})
			.then((_result) => {
				// If this request is cancelled, resolve to the latest request.
				if ((promise as any).cancelled) {
					return nuxt._asyncDataPromises[key];
				}

				let result = _result as unknown as DataT;
				if (options.transform) {
					result = options.transform(_result);
				}
				if (options.pick) {
					result = pick(result as any, options.pick) as DataT;
				}
				asyncData.data.value = result;
				asyncData.error.value = null;
				asyncData.status.value = "success";
			})
			.catch((error: any) => {
				// If this request is cancelled, resolve to the latest request.
				if ((promise as any).cancelled) {
					return nuxt._asyncDataPromises[key];
				}

				asyncData.error.value = error;
				asyncData.data.value = unref(options.default!());
				asyncData.status.value = "error";
				asyncData.isLoading.value = false;
			})
			.finally(() => {
				if ((promise as any).cancelled) {
					return;
				}

				asyncData.pending.value = false;
				nuxt.payload.data[key] = asyncData.data.value;
				if (asyncData.error.value) {
					// We use `createError` and its .toJSON() property to normalize the error
					nuxt.payload._errors[key] = createError(asyncData.error.value);
				}
				delete nuxt._asyncDataPromises[key];
			});
		nuxt._asyncDataPromises[key] = promise;
		return nuxt._asyncDataPromises[key] as Promise<void>;
	};

	const initialFetch = () => asyncData.refresh({ _initial: true });

	const fetchOnServer = options.server !== false && nuxt.payload.serverRendered;

	// Server side
	if (process.server && fetchOnServer && options.immediate) {
		const promise = initialFetch();
		if (getCurrentInstance()) {
			onServerPrefetch(() => promise);
		} else {
			nuxt.hook("app:created", () => promise);
		}
	}

	// Client side
	if (process.client) {
		// Setup hook callbacks once per instance
		const instance = getCurrentInstance();
		if (instance && !instance._nuxtOnBeforeMountCbs) {
			instance._nuxtOnBeforeMountCbs = [];
			const cbs = instance._nuxtOnBeforeMountCbs;
			if (instance) {
				onBeforeMount(() => {
					cbs.forEach((cb) => {
						cb();
					});
					cbs.splice(0, cbs.length);
				});
				onUnmounted(() => cbs.splice(0, cbs.length));
			}
		}

		if (fetchOnServer && nuxt.isHydrating && hasCachedData()) {
			// 1. Hydration (server: true): no fetch
			asyncData.pending.value = false;
			asyncData.status.value = asyncData.error.value ? "error" : "success";
		} else if (instance && ((nuxt.payload.serverRendered && nuxt.isHydrating) || options.lazy) && options.immediate) {
			// 2. Initial load (server: false): fetch on mounted
			// 3. Initial load or navigation (lazy: true): fetch on mounted
			instance._nuxtOnBeforeMountCbs.push(initialFetch);
		} else if (options.immediate) {
			// 4. Navigation (lazy: false) - or plugin usage: await fetch
			initialFetch();
		}
		if (options.watch) {
			watch(options.watch, () => asyncData.refresh());
		}
		const off = nuxt.hook("app:data:refresh", (keys) => {
			if (!keys || keys.includes(key)) {
				return asyncData.refresh();
			}
		});
		if (instance) {
			onUnmounted(off);
		}
	}

	// Allow directly awaiting on asyncData
	const asyncDataPromise = Promise.resolve(nuxt._asyncDataPromises[key]).then(() => asyncData) as AsyncData<
		ResT,
		DataE
	>;
	Object.assign(asyncDataPromise, asyncData);

	return asyncDataPromise as AsyncData<PickFrom<DataT, PickKeys>, DataE>;
};

export default useAsync;
