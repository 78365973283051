import useAppInitData from "./useAppInitData";
import useCentrifuge from "./useCentrifuge";

let instance: WebSocket;
const events = new Map<string | number | undefined | null | boolean, Function | undefined>();

type Types =
	| "invite"
	| "setBalance"
	| "contacts"
	| "social"
	| "bonus"
	| "profile"
	| "tournament"
	| "playerIdentityVerification"
	| "addPlayerLastAction"
	| "deposit"
	| "nc"
	| "moneyBox"
	| "win"
	| "promotionsPage"
	| "cash"
	| "prizeDrops"
	| "questPoints"
	| "activity"
	| "rankLeague"
	| "cashback"
	| "switchToEntries"
	| "app"
	| "season"
	| "payment"
	| "popUpAds"
	| "partnerReferral"
	| "bingo"
	| "playerVerification"
	| "freeSpin"
	| "reward";

const send = (object: Record<string | number, string | number>) => {
	if (instance) {
		instance.send(JSON.stringify(object));
	}
};

const add = (type: Types, fn: Function) => {
	if (!events.has(type)) {
		events.set(type, fn);
	}
};

const remove = (type: Types) => {
	if (!events.has(type)) {
		return;
	}
	events.delete(type);
};

const onmessage = (message: { data: MessageEvent["data"] }, isJsonParsed?: boolean) => {
	const parsedData = isJsonParsed ? message.data : parseJSON(message.data);
	if (!parsedData) {
		return;
	}
	if (events.has(parsedData.type)) {
		events.get(parsedData.type)?.(parsedData);
	}
};

const useSockets = () => {
	const { data } = useAppInitData();
	const { initCentrifuge } = useCentrifuge();
	watch(
		() => data.value?.wsConnectionData,
		() => {
			if (!isClient) {
				return;
			}
			if (data.value?.wsConnectionData?.rabbit?.url && !instance) {
				instance = new WebSocket(`${data.value?.websocket}/?token=${data.value?.token}`);
				instance.addEventListener("message", onmessage);
				return;
			}

			initCentrifuge({ wsConnectionData: data.value?.wsConnectionData, onMessageFn: onmessage });
		},
		{ immediate: true }
	);

	return { send, add, remove };
};

export default useSockets;
