import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const difference = (timestamp: number) => dayjs(timestamp).diff(dayjs(), "second");
const toTimestamp = (value: number | string | dayjs.Dayjs) => dayjs(value).valueOf();

export default function useCountdown({
	timestamp,
	formatToken = "H[ : ]mm[ : ]ss",
	onCountdownStop = () => {},
	isOptimized = false
}: {
	timestamp: string | dayjs.Dayjs | number;
	formatToken?: string;
	onCountdownStop?: () => void;
	isOptimized?: boolean;
}) {
	let time = timestamp;
	const isTimerOn = ref(true);
	const duration = ref(difference(toTimestamp(time)));
	const currentFormatToken = ref(formatToken);
	const isMore1Day = computed(() => dayjs(timestamp).diff(dayjs(new Date()), "d") >= 1);

	const { pause, resume } = useInterval(1000, {
		controls: true,
		immediate: !!time,
		callback: () => {
			if (isOptimized) {
				if (isMore1Day.value) {
					isTimerOn.value = false;
					pause();
					return;
				}
				if (!isTimerOn.value) {
					isTimerOn.value = true;
					resume();
				}
			}
			duration.value = difference(toTimestamp(time));
		}
	});

	const reset = (timestamp: string | dayjs.Dayjs | number, formatToken?: string) => {
		time = timestamp;
		if (formatToken) {
			currentFormatToken.value = formatToken;
		}
		duration.value = difference(toTimestamp(time));
		resume();
	};

	const durationLeft = computed(() => dayjs.duration(duration.value, "second").format(currentFormatToken.value));
	const durationExceeded = computed(() => duration.value <= 0);

	if (process.client) {
		watchEffect(() => {
			if (durationExceeded.value) {
				pause();
				duration.value = 0;
				onCountdownStop();
			}
		});
	}

	return { durationLeft, durationExceeded, duration, reset };
}
