import type { EventData, SocketQuestData } from "@/types";

const useQuestsSocket = () => {
	const { open } = useAppModals();
	const { add, remove } = useSockets();
	const { exitFullscreen } = useFullscreenToggle();

	const { data: questPageData, refresh: refreshQuestPageData } = useGetPageQuestData({
		immediate: false
	});
	const { data: questData, refresh: refreshQuestData } = useGetQuestData({ immediate: false });
	const { refresh: refreshAllBoardData } = useGetAllBoardData({ immediate: false });
	const introQuest = computed(() => questData.value?.data?.questInfo?.type === "intro");
	const lastTaskCompleted = computed(() => {
		const allTasks = [...(questData.value?.data?.dayInfo?.tasks || [])];
		return allTasks?.length > 0 && !!allTasks[allTasks?.length - 1]?.isCompleted;
	});

	const updateTaskState = (task: SocketQuestData, type: "complete" | "progress") => {
		const currentTask = questPageData.value?.playerData?.days?.[task.dayIndex]?.tasks?.[task.taskIndex];
		const questInfoTask = questData.value?.data?.dayInfo?.tasks?.[task.taskIndex];

		if (currentTask) {
			if (type === "complete") {
				currentTask.isCompleted = true;
			}
			if (type === "progress") {
				currentTask.progress = task.progress;
				currentTask.repeat = task.repeat;
			}
			if (task.executionTimeData) {
				currentTask.executionTimeData = task.executionTimeData;
			}
		}

		if (questInfoTask) {
			if (type === "complete") {
				questInfoTask.isCompleted = true;
			}

			if (type === "progress") {
				questInfoTask.progress = task.progress;
				questInfoTask.repeat = task.repeat;
			}

			if (task.executionTimeData) {
				questInfoTask.executionTimeData = task.executionTimeData;
			}
		}
	};

	add("questPoints", ({ action, data }: EventData<SocketQuestData>) => {
		if (action === "taskCompleted") {
			exitFullscreen();
			updateTaskState(data, "complete");

			if (introQuest.value) {
				setTimeout(() => {
					Promise.all([refreshAllBoardData(), refreshQuestData()]);
					open("LazyOModalQuestsCompleted", { task: data });
					refreshQuestPageData();
				}, 3000);
			}

			if (questData.value?.data?.questInfo?.type === "fp_challenge") {
				if (lastTaskCompleted.value) {
					open("LazyOModalFreeSpinCongratulations", { data });
				} else {
					open("LazyOModalFreeSpinTaskPassed", { data });
				}

				setTimeout(() => {
					refreshQuestPageData();
					refreshQuestData();
				}, 1000);
			}
		}

		if (action === "taskProgress") {
			updateTaskState(data, "progress");
		}

		if (
			action === "finished" ||
			action === "started" ||
			action === "dayFinished" ||
			action === "availableByRankLeague"
		) {
			setTimeout(() => {
				refreshQuestPageData();
				refreshQuestData();
			}, 1000);
		}
	});

	onBeforeUnmount(() => {
		remove("questPoints");
	});
};

export default useQuestsSocket;
