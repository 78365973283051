const useEvent = (
	type: string | Array<string>,
	callback: EventListenerOrEventListenerObject,
	options?: AddEventListenerOptions
) => {
	onMounted(() => {
		if (Array.isArray(type)) {
			type.forEach((event) => {
				window.addEventListener(event, callback, options);
			});
		} else {
			window.addEventListener(type, callback, options);
		}
	});
	onUnmounted(() => {
		if (Array.isArray(type)) {
			type.forEach((event) => {
				window.removeEventListener(event, callback, options);
			});
		} else {
			window.removeEventListener(type, callback, options);
		}
	});
};

export default useEvent;
