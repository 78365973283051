import { apiClient } from "@netgame/openapi";

import useIsGuest from "./useIsGuest";

const useGoogleOneTap = () => {
	const {
		public: { clientId }
	} = useRuntimeConfig();
	const { isDesktop } = useDevice();
	const isGuest = useIsGuest();
	const route = useRoute();
	const localPath = useLocalePath();

	const GOOGLE_SCRIPT_ID = "google-auth";

	const setOneTapSingIn = async ({ credential }: { credential: string }) =>
		await apiClient({
			path: "/rest/google/one-tap/",
			method: "post",
			parameters: {
				body: { credential }
			},
			options: {
				onResponse: ({ response }) => {
					if (response._data.status) {
						dispatchGAEvent({
							event: "registration",
							location: "oneTap",
							step: "success",
							type: "google"
						});
						window.location.reload();
					}
				}
			}
		});

	if (process.client) {
		watch(
			() => route.path,
			(value, oldValue) => {
				if (!isGuest.value) {
					return;
				}

				if (value === localPath("/") && oldValue !== localPath("/")) {
					window?.google?.accounts?.id?.prompt();
				}
			}
		);
	}

	onMounted(() => {
		const googleAuthScript = window?.document?.getElementById(GOOGLE_SCRIPT_ID);

		if (!googleAuthScript && clientId && isGuest.value) {
			const authScript = document.createElement("script");
			authScript.id = GOOGLE_SCRIPT_ID;
			authScript.src = "https://accounts.google.com/gsi/client";
			document.body.appendChild(authScript);
			setTimeout(() => {
				try {
					window?.google?.accounts?.id?.initialize?.({
						client_id: clientId,
						callback: setOneTapSingIn,
						auto_select: false,
						cancel_on_tap_outside: isDesktop,
						context: "signin"
					});
					window?.google?.accounts?.id?.prompt?.();
				} catch (e) {
					console.warn(`[useGoogleOneTap]: ${e}`);
				}
			}, 3000);
		}
	});
};

export default useGoogleOneTap;
