type ModalOpen<K extends string, P> = (name: K, props?: P) => void;
type ModalClose<K extends string> = (name: K) => void;

const useWelcomeModal = <K extends string, P>(open: ModalOpen<K, P>, close: ModalClose<K>) => {
	const checkDailyOpen = ({ storage = "", modal }: { storage?: string; modal?: string } = {}) => {
		const showStorage = localStorage.getItem(storage);
		const today = new Date().toLocaleDateString();
		if (showStorage === today) {
			return;
		}
		open(modal as K);
	};
	const closeModal = ({
		storage = "",
		modal,
		location = "",
		navigate
	}: { storage?: string; modal?: string; location?: string; navigate?: string } = {}) => {
		localStorage.setItem(storage, new Date().toLocaleDateString());
		if (navigate) {
			navigateTo(navigate);
			dispatchGAEvent({
				event: "click_button",
				location
			});
		}

		close(modal as K);
	};
	const openModalOnce = ({
		storage = "",
		storageSkip = "",
		modal,
		endDate = ""
	}: {
		storage?: string;
		storageSkip?: string;
		modal?: string;
		endDate?: Date | string;
	} = {}) => {
		const showStorage = localStorage.getItem(storage);
		if (showStorage) {
			return;
		}
		localStorage.setItem(storage, new Date(endDate).toLocaleDateString());
		localStorage.setItem(storageSkip, new Date().toLocaleDateString());
		open(modal as K);
	};

	return { checkDailyOpen, closeModal, openModalOnce };
};

export default useWelcomeModal;
