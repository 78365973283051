import useAsyncFetch from "./useAsyncFetch";

const useGetBalanceData = ({ immediate = false, server = false }: { immediate?: boolean; server?: boolean } = {}) =>
	useAsyncFetch({
		path: "/rest/player/balance/",
		method: "get",
		options: {
			immediate,
			server,
			transform(data) {
				const entriesBalance = data?.data?.entries;
				return { ...data, entriesBalance };
			}
		}
	});

export default useGetBalanceData;
