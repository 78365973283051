import { apiClient } from "@netgame/openapi";

import useAppInitData from "./useAppInitData";
import useEvent from "./useEvent";

type GameModes = "SweepStakes" | "TournamentPoints";

const pathConfig: Record<GameModes, "sweep-stakes" | "tournament-points"> = {
	SweepStakes: "sweep-stakes",
	TournamentPoints: "tournament-points"
};

const useSwitchMode = () => {
	const { data: appInitData, refresh } = useAppInitData();

	const gameMode = computed(() => appInitData.value?.gameMode ?? "TournamentPoints");
	const activeGameMode = computed(() => (gameMode.value === "SweepStakes" ? "entries" : "coins"));
	const isSweepStakes = computed(() => gameMode.value === "SweepStakes");

	const getSwitchPath = (mode?: GameModes) => {
		if (mode) {
			return pathConfig[mode];
		}

		return isSweepStakes.value ? pathConfig.TournamentPoints : pathConfig.SweepStakes;
	};

	const handleSwitchGameMode = async (mode?: GameModes) =>
		await apiClient({
			path: `/rest/player/game-mode/switch-to-${getSwitchPath(mode)}/`,
			method: "get",
			options: {
				onResponse: ({ response }) => {
					if (response._data.success && appInitData.value) {
						appInitData.value.gameMode = response._data.gameMode;
						dispatchQuasarEvent("quasar:toggleGameMode", {
							detail: response._data.gameMode
						});
					}
				}
			}
		});

	useEvent(["nuxt:changeBalanceSwitch"], async () => {
		await refresh();
	});

	return { gameMode, activeGameMode, isSweepStakes, handleSwitchGameMode };
};

export default useSwitchMode;
