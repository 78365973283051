import { useThrottleFn } from "@vueuse/core";

import type { EventData, Promotion } from "@/types";

const DELTA_SCROLL = 1500;

const useExitModal = () => {
	const endTime = useState("endTimeState", () => 0);
	const { data: appInitData } = useAppInitData();
	const { isMobile } = useDevice();
	const route = useRoute();
	const { open, close, isOpen } = useAppModals();
	const isGuest = useIsGuest();
	const { add } = useSockets();
	const { isActiveDailyLogin, refresh } = useDailyLogin();

	const lastScrollTop = ref(0);

	const { data, refresh: refreshExitPromoData } = useAsyncFetch({
		path: "/rest/cash/promo-offer-preset/exit/",
		method: "get",
		options: { cached: true, immediate: !isGuest.value }
	});
	const { refresh: refreshPageHomeData } = useAsyncFetch({
		path: "/rest/page/home/",
		method: "get",
		options: {
			immediate: false
		}
	});
	const { refresh: refreshPromotionsPageData } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			immediate: false
		}
	});

	const userDepositsCount = computed(() => appInitData.value?.depositsCount);
	const promoOffer = computed(() => data.value?.exitPopupPromoOfferPreset);
	const isShowExitModal = computed(
		() =>
			isClient && localStorage.getItem("exitPopupTime") && localStorage.getItem("isShowExitPopup") && promoOffer.value
	);
	const isActiveUser = computed(() => !isGuest.value && promoOffer.value && !isOpen("LazyOModalGame"));

	const handleOpenCash = (location?: string) => {
		if (!promoOffer.value) {
			return;
		}

		const url = promoOffer.value?.promoOfferPreset
			? `/cash/deposit-by-money/${promoOffer.value?.promoOfferPreset.id}/promoOffer/`
			: `/cash/deposit-by-money/${promoOffer.value?.preset.id}/preset/`;

		dispatchGAEvent({
			event: "click_button",
			button_name: "open_cash",
			location: location || ""
		});

		window?.$cash?.$router?.push?.(url);
	};

	const resetTimer = () => {
		endTime.value = 0;
	};

	const { durationLeft, durationExceeded, reset } = useCountdown({
		timestamp: endTime.value,
		formatToken: "mm[:]ss",
		onCountdownStop: resetTimer
	});
	const startTimer = () => {
		if (isClient && !localStorage.getItem("isShowExitPopup")) {
			const time = Date.now() + 10 * 60 * 1000;
			localStorage.setItem("exitPopupTime", time.toString());
			localStorage.setItem("isShowExitPopup", "true");
			endTime.value = time;
			open("LazyOModalExit");
		}
	};

	const onBackButtonPress = () => {
		if (isActiveUser.value && isMobile) {
			startTimer();
		}
	};

	const handleMouseLeave = () => {
		if (!isActiveUser.value || (route.path !== "/" && !route.path.includes("issues"))) {
			return;
		}
		startTimer();
	};

	const handleScroll = useThrottleFn(() => {
		if (!isActiveUser.value || (route.path !== "/" && !route.path.includes("promotions")) || !isMobile) {
			return;
		}

		const currentScrollTop = document.documentElement.scrollTop;
		const scrollDirection = currentScrollTop > lastScrollTop.value ? "down" : "up";
		const scrollDifference = Math.abs(currentScrollTop - lastScrollTop.value);

		if (scrollDifference > DELTA_SCROLL) {
			lastScrollTop.value = currentScrollTop;

			if (scrollDirection === "up") {
				startTimer();
			}
		}
	}, 200);

	useEvent("scroll", handleScroll);
	useEvent("popstate", onBackButtonPress, { capture: true });

	watch(endTime, (value) => {
		reset(value || 0);

		if (!value) {
			isClient && localStorage.removeItem("exitPopupTime");
			close("LazyOModalExit");
		}
	});

	watch(userDepositsCount, () => {
		isClient && localStorage.removeItem("exitPopupTime");
		resetTimer();

		setTimeout(() => {
			refreshExitPromoData();
		}, 2000);
	});

	watch(promoOffer, (value) => {
		if (!value && isOpen("LazyOModalExit")) {
			close("LazyOModalExit");
		}
	});

	add("promotionsPage", ({ action }: EventData<Promotion>) => {
		if (action === "update") {
			refreshPageHomeData();
			refreshPromotionsPageData();
			refreshExitPromoData();
		}
	});

	add("activity", ({ action }: EventData<unknown>) => {
		if (action === "day_24h") {
			if (isClient && !localStorage.getItem("exitPopupTime")) {
				localStorage.removeItem("isShowExitPopup");
			}

			if (isActiveDailyLogin.value) {
				refresh();
			}
		}
	});

	onMounted(() => {
		if (isClient && localStorage.getItem("exitPopupTime")) {
			endTime.value = Number(localStorage.getItem("exitPopupTime"));
		}
		document.addEventListener("mouseleave", handleMouseLeave);
	});

	onUnmounted(() => {
		document.removeEventListener("mouseleave", handleMouseLeave);
	});

	return {
		endTime,
		durationLeft,
		durationExceeded,
		isShowExitModal,
		promoOffer,
		handleOpenCash
	};
};

export default useExitModal;
