const getCoins = (prizes: Array<{ coins?: number } | string>) =>
	prizes?.reduce((acc, prize) => {
		if (typeof prize === "object" && "coins" in prize && prize?.coins) {
			return acc + prize.coins;
		}

		if (typeof prize === "string") {
			const coins = parseInt(prize, 10);
			return acc + coins;
		}

		return acc;
	}, 0);

export default getCoins;
