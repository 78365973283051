import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

const useGetRankedLeagueInfo = () => {
	const { data: appInit, refresh: refreshAppInitData } = useAppInitData();
	const isGuest = useIsGuest();

	return useAsyncFetch({
		path: "/rest/rank-league/info/",
		method: "get",
		options: {
			immediate: !isGuest.value && !appInit.value?.rankLeague?.enabled,
			watch: [
				() => isGuest.value,
				() => appInit.value?.phoneConfirmed,
				() => appInit.value?.profileCompleted,
				() => appInit.value?.emailConfirmed
			],
			cached: true
		},
		fetchOptions: () => ({
			onResponse: ({ response }) => {
				if (response?._data?.available) {
					refreshAppInitData();
				}
			}
		})
	});
};

export default useGetRankedLeagueInfo;
