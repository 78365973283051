import { toast } from "vue3-toastify";

import useSockets from "./useSockets";

const useAppRefreshSocket = <T extends (key: string) => string>(t: T) => {
	const { add } = useSockets();

	add("app", ({ action }: { action: string }) => {
		if (action === "reload") {
			toast.error(
				({ closeToast }) =>
					h("div", { class: "content" }, [
						t("Updated content is available. Please refresh the page."),
						h("div", { class: "actions" }, [
							h("span", { onClick: () => window.location.reload() }, t("refresh")),
							h("span", { onClick: closeToast }, t("dismiss"))
						])
					]),
				{
					containerId: "app-update-notify",
					icon: h("i", { class: "toast-icon refresh" }),
					dangerouslyHTMLString: true,
					theme: toast.THEME.COLORED,
					position: toast.POSITION.TOP_CENTER,
					transition: toast.TRANSITIONS.SLIDE,
					autoClose: false,
					closeOnClick: false,
					closeButton: false
				}
			);
		}
	});
};

export default useAppRefreshSocket;
