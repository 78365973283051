import useAsyncFetch from "./useAsyncFetch";
import useGetQuestData from "./useGetQuestData";
import useIsGuest from "./useIsGuest";

const useGetAllBoardData = ({
	onboardScenario = "introQuest",
	immediate,
	cached = true
}: { onboardScenario?: "introQuest"; immediate?: boolean; cached?: true } = {}) => {
	const isGuest = useIsGuest();
	const { data: questData } = useGetQuestData();

	return useAsyncFetch({
		path: "/rest/player/all-board/",
		method: "get",
		options: {
			cached,
			immediate: immediate || !isGuest.value,
			watch: [() => questData.value?.data?.questInfo?.type === "intro", () => isGuest.value]
		},
		fetchOptions: {
			query: {
				scenario: onboardScenario
			}
		}
	});
};

export default useGetAllBoardData;
