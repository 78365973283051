export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=5"},{"charset":"utf-8"},{"name":"description","content":"Personalized approach. Free coins, daily. Support 24/7. Play Social Promotional Games online at funzcity.com"},{"name":"og:url","content":"https://funzcity.com/"},{"name":"og:type","content":"website"},{"name":"og:title","content":"FunzCity Platform for Social Gaming"},{"name":"og:description","content":"Personalized approach. Free coins, daily. Support 24/7. Play Social Promotional Games online at funzcity.com"},{"name":"og:site_name","content":"FunzCity"},{"name":"og:image","content":"https://funzcity.com/uploads/media/ogImage2.jpg"},{"name":"twitter:title","content":"FunzCity Platform for Social Gaming"},{"name":"twitter:description","content":"Personalized approach. Free coins, daily. Support 24/7. Play Social Promotional Games online at funzcity.com"},{"name":"twitter:site","content":"FunzCity"},{"name":"twitter:image","content":"https://funzcity.com/uploads/media/ogImage2.jpg"},{"name":"twitter:image:src","content":"https://funzcity.com/uploads/media/ogImage2.jpg"},{"name":"twitter:card","content":"summary_large_image"}],"link":[{"rel":"icon","type":"image/png","href":"/nuxt-img/favicon-32x32.png"},{"rel":"icon","type":"image/png","href":"/nuxt-img/favicon-16x16.png"},{"rel":"shortcut icon","href":"/nuxt-img/favicon.ico"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"class":"funzCity","lang":"en"},"viewport":"width=device-width, initial-scale=1, maximum-scale=5"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false