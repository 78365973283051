/* eslint-disable indent */
import type { NitroFetchOptions, NitroFetchRequest } from "nitropack";

import { replacePathParameters } from "./replacePathParameters";
import { v1 } from "./schemas";
import type { ExtractFromAPI, PossibleMethods, Parameters } from "./types";

export const apiClient = <
	Path extends keyof v1.paths,
	Method extends keyof v1.paths[Path],
	Response extends ExtractFromAPI<v1.paths, Path, Method>
>({
	path,
	method,
	options,
	parameters
}: {
	path: Path;
	method: Method;
	options?: NitroFetchOptions<NitroFetchRequest>;
	parameters?: Parameters<v1.paths[Path][Method]>;
}) => {
	const { path: parametersPaths, body } = (parameters || {}) as unknown as {
		path: Record<string, string>;
		body: Record<string, string>;
	};

	return $fetch<Response>(replacePathParameters(path, parametersPaths), {
		baseURL: undefined,
		...options,
		body: body || options?.body,
		method: method as PossibleMethods | undefined
	});
};
