import useIsGuest from "./useIsGuest";
import useIsRegistrationComplete from "./useIsRegistrationComplete";

const useIsRegistrationCompleteGuard = () => {
	const isGuest = useIsGuest();
	const isRegistrationComplete = useIsRegistrationComplete();

	return createGuard({ condition: () => !isGuest.value && isRegistrationComplete() });
};

export default useIsRegistrationCompleteGuard;
