const play = (
	id: NonNullable<Awaited<ReturnType<typeof useBonusesData>>["data"]["value"]>["audios"][number]["name"]
) => {
	const audio = document.getElementById(id) as HTMLAudioElement;
	if (audio) {
		setTimeout(() => {
			audio.play();
		}, 150);
	}
};

const pause = (
	id: NonNullable<Awaited<ReturnType<typeof useBonusesData>>["data"]["value"]>["audios"][number]["name"]
) => {
	const audio = document.getElementById(id) as HTMLAudioElement;
	if (audio) {
		const playPromise = audio.play();
		if (playPromise !== undefined) {
			playPromise
				.then(() => {
					audio.pause();
				})
				.catch((error) => console.log(error));
		}
	}
};

const activeChestPauseDelay = 5000;

const useMysteryBoxAudios = () => {
	const { data } = useBonusesData();

	const activeChestTimer = useState<ReturnType<typeof setTimeout> | number | null>("activeChestTimer", () => null);

	const id = computed(() => (data.value?.dailyChests?.available ? "soundActiveChests" : "soundDisabledChests"));

	const playMysteryBoxAudio = () => {
		play(id.value);
	};

	const playActiveChest = () => {
		pause("soundActiveChests");
		pause("soundDisabledChests");
		play("soundOpenChest");

		activeChestTimer.value = setTimeout(() => {
			pause("soundOpenChest");
			play("soundDisabledChests");
		}, activeChestPauseDelay);
	};

	const pauseAllChests = () => {
		if (activeChestTimer.value) {
			clearTimeout(activeChestTimer.value);
			activeChestTimer.value = null;
		}
		pause("soundActiveChests");
		pause("soundDisabledChests");
		pause("soundOpenChest");
	};
	return {
		data,
		playActiveChest,
		playMysteryBoxAudio,
		play,
		pause,
		pauseAllChests
	};
};

export default useMysteryBoxAudios;
