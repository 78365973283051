import { toast } from "vue3-toastify";

import type { EventData, SocketSeasonData } from "@/types";

const showProgressNotify = ({
	t,
	prize,
	isMobile,
	currentLevel,
	isLastLevel
}: {
	t: ReturnType<typeof useT>["t"];
	prize: number;
	isMobile: boolean;
	currentLevel: number;
	isLastLevel: boolean;
}) => {
	const prizeImg = () => {
		if (isLastLevel) {
			return "large";
		}

		if (prize > 14000) {
			return "middle";
		}

		return "base";
	};

	toast.success(
		`
			<div class="notification-content">
				<div class="notification-title">${t("Congratulations!")}</div>
				<div class="notification-level">
					${t("You completed Level")}
					<span>${currentLevel}</span>
					${t("in Power Tower and won")}
					<span class="prize">${prize}</span>
				</div>
			</div>
		`,
		{
			dangerouslyHTMLString: true,
			toastClassName: "notification-season",
			theme: toast.THEME.LIGHT,
			position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 9000,
			icon: h("img", {
				src: `/nuxt-img/season/prize-${prizeImg()}.png`
			}),
			closeButton: () => h("i", { class: "toast-icon icon-close" })
		}
	);
};

const useSeasonSocket = () => {
	const { t } = useT();
	const { add } = useSockets();
	const { exitFullscreen } = useFullscreenToggle();
	const { isMobile } = useDevice();
	const { data: appInitData } = useAppInitData();
	const { data: seasonData } = useSeasonsData({ immediate: false });

	add("season", ({ action, data }: EventData<SocketSeasonData>) => {
		const { season } = appInitData.value || {};
		const maxLvl = seasonData.value?.levels?.length || 30;

		if (action === "progressUpdate" && season?.totalProgressPercents !== undefined) {
			season.totalProgressPercents = data?.levelProgressPercents ?? 0;
			season.currentLevel = data?.currentLevel ?? 1;
		}

		if (action === "prizeAssigned" && data?.entries) {
			exitFullscreen();

			showProgressNotify({
				t,
				prize: data.entries * 100,
				isMobile,
				currentLevel: season?.currentLevel || 1,
				isLastLevel: season?.currentLevel === maxLvl
			});
		}

		if (action === "availableByDeposit") {
			if (season?.isAvailable !== undefined) {
				season.isAvailable = true;
			}
		}
	});
};

export default useSeasonSocket;
