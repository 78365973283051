import { Centrifuge } from "centrifuge";

import type { InitDataResponseData } from "./useAppInitData";

type OnMessageFn = (message: { data: MessageEvent["data"] }, isJsonParsed?: boolean) => void;

let centrifuge: Centrifuge | null;

const connectCentrifuge = ({ endpoint, token }: { endpoint: string; token: string }) => {
	if (centrifuge) {
		return centrifuge;
	}
	centrifuge = new Centrifuge(endpoint, { token });
	try {
		centrifuge.connect();
	} catch {
		console.error("[useCentrifuge]: Here is some trouble with `centrifuge.connect()`");
	}
};

const disconnectCentrifuge = () => {
	if (!centrifuge) {
		return;
	}
	centrifuge?.disconnect();
	centrifuge = null;
};

const subscribeCentrifuge = ({
	channel,
	token,
	onmessage
}: {
	channel: string;
	token: string;
	onmessage: OnMessageFn;
}) => {
	if (!centrifuge) {
		return;
	}
	const sub = centrifuge.newSubscription(channel, { token });
	sub.on("publication", (message) => {
		onmessage({ data: message.data }, true);
	});
	sub.subscribe();
	return sub;
};

const useCentrifuge = () => {
	const initCentrifuge = ({
		wsConnectionData,
		onMessageFn
	}: {
		wsConnectionData: NonNullable<InitDataResponseData>["wsConnectionData"];
		onMessageFn: OnMessageFn;
	}) => {
		if (
			centrifuge &&
			Object.keys(centrifuge.subscriptions()).length === wsConnectionData?.centrifugo?.connections?.length
		) {
			return;
		}

		if (wsConnectionData?.centrifugo?.url) {
			disconnectCentrifuge();
			connectCentrifuge({
				endpoint: wsConnectionData.centrifugo.url,
				token: wsConnectionData.centrifugo.connectionToken
			});

			wsConnectionData.centrifugo.connections.forEach((connect) => {
				if (!connect) {
					return;
				}
				subscribeCentrifuge({
					channel: connect.channelName,
					token: connect.token,
					onmessage: onMessageFn
				});
			});
		}
	};

	return { initCentrifuge };
};

export default useCentrifuge;
