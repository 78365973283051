type Status = {
	prizeValues: { coins: number; entries: number } | { coins?: undefined; entries?: undefined };
	description: string;
	image: string;
	unlocked?: boolean;
	slug?: string;
	sumExperience?: number;
};

type ExtendedStatus = Status & { completed: boolean };

const updatedStatuses = (statuses: Array<Status>): Array<ExtendedStatus> =>
	statuses.reduce<Array<ExtendedStatus>>((acc, status) => {
		if (status.unlocked) {
			return [...acc.map((element) => ({ ...element, completed: true })), { ...status, completed: false }];
		}
		return [...acc, { ...status, completed: false }];
	}, []);

const useGetRankedWheelsData = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const { t } = useT();
	const isGuest = useIsGuest();

	const wheelsDescriptions = {
		rookie: t(
			"Start your FunzCity adventure as a Rookie! Spin the City Wheel for a chance to win up to CC 400 and kickstart your journey with excitement."
		),
		gamer: t(
			"As a Gamer, the stakes get higher. Enjoy daily prizes up to CC 500 and FC 175,000, and dive deeper into the world of FunzCity gaming."
		),
		voyager: t(
			"Embark on a greater adventure as a Voyager! Now, the City Wheel minimum prize jumps to CC 150, with potential wins up to CC 600, making every spin more thrilling."
		),
		showstopper: t(
			"Steal the spotlight as a Showstopper! Watch as the City Wheel spins in your favor, offering increasingly substantial rewards as you dazzle the FunzCity scene."
		),
		mogul: t(
			"Rise to Mogul status and experience the luxury of higher stakes. Your influence expands, and so do your potential winnings on the City Wheel."
		),
		highroller: t(
			"As a Highroller, you're in it for the big wins. Expect the City Wheel to reflect your status, with even larger prizes within reach, pushing the excitement to new heights."
		),
		headliner: t(
			"Make headlines as a Headliner with your remarkable achievements. At this level, the City Wheel's generosity matches your elevated status."
		),
		baron: t(
			"Command respect as a Baron, where your spin on the City Wheel could yield substantial riches. Your journey in FunzCity is now legendary, with rewards to match."
		),
		legend: t(
			"As a Legend, your name echoes through FunzCity. The City Wheel sectors are a testament to your epic journey, offering substantial prizes worthy of your legendary status."
		),
		overlord: t(
			"Reach the pinnacle of FunzCity as an Overlord. Here, CC 500 is just the starting point on the City Wheel, with the chance to claim the ultimate prize of CC 5,000!"
		)
	};

	const rankLeagueWheelsFetch = useAsyncFetch({
		path: "/rest/page/rank-league/",
		method: "get",
		options: {
			immediate: immediate && !isGuest.value,
			watch: [() => isGuest.value],
			transform: (data) => {
				const wheels = data?.payload?.data?.statusWheelData?.map((wheel) => ({
					...wheel,
					prizeValues: !Array.isArray(wheel?.prizeValues)
						? { coins: wheel?.prizeValues?.coins || 0, entries: wheel?.prizeValues?.entries || 0 }
						: {},
					description: wheelsDescriptions[wheel?.slug as keyof typeof wheelsDescriptions],
					image: `/nuxt-img/vip/level-rank/wheel-${wheel?.slug as keyof typeof wheelsDescriptions}.webp`
				}));

				const extendByCompleted = updatedStatuses(wheels || []);

				return { wheels: extendByCompleted };
			}
		}
	});

	return { ...rankLeagueWheelsFetch, wheelsDescriptions };
};

export default useGetRankedWheelsData;
