import useHasTaoPopupPresetPackage from "./useHasTaoPopupPresetPackage";
import useIsGuest from "./useIsGuest";

const useTaoPopupPresetPackageGuard = () => {
	const isGuest = useIsGuest();
	const hasPopupPresetPackage = useHasTaoPopupPresetPackage();

	return createGuard({ condition: () => !isGuest.value && hasPopupPresetPackage() });
};

export default useTaoPopupPresetPackageGuard;
