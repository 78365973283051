import type { v1, ExtractFromAPI } from "@netgame/openapi";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useCountdown from "./useCountdown";

dayjs.extend(duration);

type ScratchCardsInfoData = ExtractFromAPI<v1.paths, "/rest/scratch-card-lottery/get/", "get">;

const useScratchCard = ({
	immediate = true,
	redirectCb = () => {}
}: {
	immediate?: boolean;
	timeFormats?: {
		days: string;
		hours: string;
	};
	smartFormatTime?: boolean;
	redirectCb?: () => void;
} = {}) => {
	const { data: appInitData } = useAppInitData();

	const { data, refresh } = useAsyncFetch({
		path: "/rest/scratch-card-lottery/get/",
		method: "get",
		options: {
			immediate:
				immediate && (appInitData.value?.scratchCardLottery?.isActive || appInitData.value?.magicBox?.isActive),
			cached: true,
			transform: (data) => data.data as ScratchCardsInfoData["data"]
		}
	});

	const activeCard = computed(() => {
		const indexReady = data.value?.cards?.findIndex((item) => item.status === "ready_for_activate") ?? -1;
		const indexAvaliable = data.value?.cards?.findIndex((item) => item.status === "available") ?? -1;
		if (indexReady !== -1) {
			return data.value?.cards?.[indexReady];
		}
		if (indexAvaliable !== -1) {
			return data.value?.cards?.[indexAvaliable];
		}
		return data.value?.cards?.[0];
	});

	const { durationLeft, reset } = useCountdown({
		timestamp: data.value?.finishedAt ?? "",
		formatToken:
			dayjs(data.value?.finishedAt ?? "").diff(dayjs(), "d") >= 1 ? "DD[ d ] HH[ h ]mm[ m ]" : "HH[ h ]mm[ m ]ss[ s ]",
		isOptimized: true,
		onCountdownStop: () => {
			refresh();
			redirectCb();
			if (appInitData.value?.scratchCardLottery) {
				appInitData.value.scratchCardLottery.isActive = false;
			}
		}
	});

	watch(
		() => data.value?.finishedAt,
		() => {
			reset(data.value?.finishedAt ?? "");
		}
	);

	return { data, refresh, activeCard, durationLeft };
};

export default useScratchCard;
