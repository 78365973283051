import useStatefulCookie from "./useStatefulCookie";

const useCookiesSetPromoCode = () => {
	const route = useRoute();
	const { invited_by: invitedBy, refcode } = route.query;
	const fromQuery = ref(locationQueryValueToString(invitedBy));
	const fromQueryRef = ref(locationQueryValueToString(refcode));

	const invitedByCookie = useStatefulCookie<string | null>("invited_by", {
		expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
		path: "/"
	});

	const refCookie = useStatefulCookie<string | null>("refcode", {
		expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
		path: "/"
	});

	watch(fromQuery, (val) => {
		invitedByCookie.value = val;
	});

	watch(fromQueryRef, (val) => {
		refCookie.value = val;
	});

	onMounted(() => {
		if (invitedBy) {
			invitedByCookie.value = locationQueryValueToString(invitedBy);
		}

		if (refcode) {
			refCookie.value = locationQueryValueToString(refcode);
		}
	});

	return {
		invitedByCookie: invitedByCookie.value ? invitedByCookie : fromQuery,
		refCookie: refCookie.value ? refCookie : fromQueryRef
	};
};

export default useCookiesSetPromoCode;
