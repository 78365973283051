import type { AsyncDataOptions } from "#app";

import type { ExtractFromAPI, v1 } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

type Response = Required<ExtractFromAPI<v1.paths, "/rest/quest/", "get">>;

const useGetQuestData = ({ cached = true, ...restOptions }: AsyncDataOptions<Response> & { cached?: true } = {}) => {
	const isGuest = useIsGuest();
	const asyncData = useAsyncFetch({
		path: "/rest/quest/",
		method: "get",
		options: {
			cached,
			immediate: !!restOptions?.immediate && !isGuest.value,
			watch: [() => isGuest.value]
		}
	});
	return {
		...asyncData
	};
};

export default useGetQuestData;
