const getEntries = (prizes: Array<{ entries?: number } | string>) =>
	prizes?.reduce((acc, prize) => {
		if (typeof prize === "object" && "entries" in prize && prize?.entries) {
			return acc + prize.entries;
		}

		if (typeof prize === "string") {
			return acc;
		}

		return acc;
	}, 0);

export default getEntries;
