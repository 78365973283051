import { apiClient } from "@netgame/openapi";

import useAppInitData from "./useAppInitData";
import useGetTournamentData from "./useGetTournamentData";
import useIsGuest from "./useIsGuest";
import useLoginGuard from "./useLoginGuard";
import useSwitchMode from "./useSwitchMode";

type Section = "pageRaces";
type GameMode = "TournamentPoints" | "SweepStakes";

const useTournamentNotification = () => {
	const { data: appInitData } = useAppInitData();
	const { data: tournamentData } = useGetTournamentData();
	const isGuest = useIsGuest();
	const loginGuard = useLoginGuard();
	const { gameMode, isSweepStakes } = useSwitchMode();

	const activeTourId = computed(() => tournamentData.value?.data?.id);
	const activeStatus = computed(() => tournamentData.value?.data?.isActive || !tournamentData.value?.data?.isOpen);
	const tournamentNotVisited = computed(
		() => appInitData.value?.visitedRaces?.[gameMode.value as GameMode] !== activeTourId.value
	);
	const entriesActiveTournament = computed(
		() => isSweepStakes.value && activeStatus.value && tournamentNotVisited.value
	);

	const initVisitedRaces = () => {
		if (!appInitData.value?.visitedRaces) {
			return;
		}
		if (Array.isArray(appInitData.value.visitedRaces)) {
			appInitData.value.visitedRaces = {};
		}
	};

	const setVisitedTournament = ({
		section = "pageRaces",
		tournamentId
	}: {
		section?: Section;
		tournamentId?: number;
	} = {}) => {
		if (!activeStatus.value) {
			return;
		}

		if (!tournamentNotVisited.value) {
			return;
		}

		if (appInitData.value?.visitedRaces) {
			appInitData.value.visitedRaces = {
				...appInitData.value.visitedRaces,
				[gameMode.value as GameMode]: tournamentId || activeTourId.value
			};
		}

		apiClient({
			path: "/rest/visited-sections/visit/",
			method: "post",
			parameters: { body: { section, tournamentId: tournamentId || activeTourId.value } }
		});
	};

	watch(isGuest, (val) => {
		if (!val) {
			initVisitedRaces();
		}
	});

	onMounted(() => {
		loginGuard({
			success: () => {
				initVisitedRaces();
			}
		});
	});

	return {
		entriesActiveTournament,
		setVisitedTournament
	};
};

export default useTournamentNotification;
