import useLoginGuard from "./useLoginGuard";
import useSwitchMode from "./useSwitchMode";

const useOpenGame = <K extends string, P>(open: (name: K, props?: P) => void, condition = true) => {
	const loginGuard = useLoginGuard();
	const { activeGameMode } = useSwitchMode();

	const handleOpenGame = (slug?: string, location?: string, isMoney = true) => {
		loginGuard({
			success: async () => {
				if (condition) {
					await navigateTo({ query: { game: slug, isMoney: isMoney ? "true" : undefined } });
					open("LazyOModalGame" as K);

					dispatchGAEvent({
						event: "gameplay",
						step: "start",
						type: activeGameMode.value
					});
				}
			},
			fail: () => {
				if (location) {
					open("LazyOModalSignup" as K, { location } as P);
					return;
				}
				open("LazyOModalSignup" as K);
			}
		});
	};

	return {
		handleOpenGame
	};
};

export default useOpenGame;
