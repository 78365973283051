<script setup lang="ts">
const { modals, close, open } = useAppModals();
const isGuest = useIsGuest();
const { data: appInit } = await useAppInitData();
const route = useRoute();
const { isOneClickRegister } = useOtpGetFlow();
const { checkRestoreLink } = useAccountChangePassword();
const { isActiveDailyLogin } = useDailyLogin();
const { isFreeSpinQuest } = useFreeSpinQuest();
const { bundleOffer } = useHomePage({ immediate: false });

const dictionary = new Map([
	["login", "LazyOModalLogin"],
	["register", isOneClickRegister ? "LazyOModalOneClickSignup" : "LazyOModalSignup"],
	["register1", "LazyOModalSignup"]
]);

onMounted(() => {
	const { openModal, errorMessage, invited_by: invitedBy, "change-password": changePasswordToken } = route.query;
	if (openModal === "app-flip-the-coin-modal") {
		open("LazyOModalFlipTheCoin");
	}
	if (!appInit.value?.isGuest && openModal === "deposit-streak" && appInit.value?.depositStreak?.isActive) {
		open("LazyOModalDepositStreak");
	}
	if (openModal === "app-season-modal" && appInit.value?.season?.isEnabled) {
		open("LazyOModalSeason");
	}
	if (openModal === "app-freeplay-modal" && isFreeSpinQuest.value && !isGuest.value) {
		open("LazyOModalFreeSpin");
	}
	if (openModal === "daily-login" && isActiveDailyLogin.value) {
		open("LazyOModalDailyLogin");
	}
	if (openModal === "bundle-offer" && bundleOffer.value) {
		open("LazyOModalBundle");
	}
	if (changePasswordToken) {
		checkRestoreLink();
	}
	if (appInit.value?.isGuest === false) {
		return;
	}
	if (invitedBy) {
		open("LazyOModalSignup");
	}

	if (typeof openModal !== "string" || !dictionary.has(openModal)) {
		return;
	}
	const modal = dictionary.get(openModal);
	if (!modal) {
		return;
	}
	const error = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
	const errorString = typeof error === "string" ? error : undefined;

	open(modal as Parameters<typeof open>[0], { error: errorString });
});
</script>

<template>
	<div>
		<Teleport to="body">
			<TransitionGroup :name="modals.prevSize === 0 || modals.nextSize === 0 ? 'modal' : 'multiple'" appear>
				<AOverlay
					v-for="[ModalComponent, properties] in modals.items.entries()"
					:id="ModalComponent.replace('LazyO', '')"
					:key="ModalComponent"
					bg-color="var(--ceuta)"
					:modifiers="['flex-center', 'fixed', 'auto']"
				>
					<component v-bind="{ onClose: () => close(ModalComponent), ...properties }" :is="ModalComponent" />
				</AOverlay>
			</TransitionGroup>
		</Teleport>
	</div>
</template>

<style scoped lang="scss">
.modal-enter-active,
.modal-leave-active {
	transition: opacity 350ms ease-in-out;
}

.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9998;
	cursor: auto;

	&#ModalJackpotsGames,
	&#ModalMoneyBox {
		z-index: 6000;
		overflow: hidden;
		align-items: initial;
	}
	&#ModalRacesGames {
		z-index: 100002;
		overflow: hidden;
		align-items: initial;
	}
	&#ModalQuests {
		z-index: 5999;
	}
	&#ModalOneClickSignup,
	&#ModalOneClickSignupComplete,
	&#ModalDepositStreakHowItWorks {
		z-index: 100002;
	}
	&#ModalSignup,
	&#ModalLogin {
		align-items: initial;
	}
	&#ModalEmailConfirm {
		z-index: 10003;
	}
	&#ModalWelcome {
		z-index: 10002;
	}
	&#ModalPresetPackage {
		z-index: 10001;
	}
}
</style>
