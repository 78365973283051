import { default as indexjS2aXA914KMeta } from "/app/apps/funzcity/pages/affiliates/index.vue?macro=true";
import { default as partners_45accountmRH2alWi8NMeta } from "/app/apps/funzcity/pages/affiliates/partners-account.vue?macro=true";
import { default as change_45passwordk1ecCugyh3Meta } from "/app/apps/funzcity/pages/change-password.vue?macro=true";
import { default as complete_45profilerGCNiIT3mJMeta } from "/app/apps/funzcity/pages/complete-profile.vue?macro=true";
import { default as email_45edit94qz6GdewhMeta } from "/app/apps/funzcity/pages/email-edit.vue?macro=true";
import { default as email_45verifyDy5IKvf2j2Meta } from "/app/apps/funzcity/pages/email-verify.vue?macro=true";
import { default as gametzV2dJHwoHMeta } from "/app/apps/funzcity/pages/game.vue?macro=true";
import { default as _91pageName_93grvT63ebMaMeta } from "/app/apps/funzcity/pages/games/[pageName].vue?macro=true";
import { default as how_45it_45works84Pkx0ycvVMeta } from "/app/apps/funzcity/pages/how-it-works.vue?macro=true";
import { default as indexZdJHgWvbPZMeta } from "/app/apps/funzcity/pages/index.vue?macro=true";
import { default as _91pageName_93x9AdqUL0X0Meta } from "/app/apps/funzcity/pages/issues/[pageName].vue?macro=true";
import { default as favoritesZuc3OG8iwyMeta } from "/app/apps/funzcity/pages/issues/favorites.vue?macro=true";
import { default as _91name_93SwpyA1Awq5Meta } from "/app/apps/funzcity/pages/page/[name].vue?macro=true";
import { default as phone_45edit2IHaNtm4yvMeta } from "/app/apps/funzcity/pages/phone-edit.vue?macro=true";
import { default as phone_45verifydHbWs8fqIXMeta } from "/app/apps/funzcity/pages/phone-verify.vue?macro=true";
import { default as piggy_45bankEbyZyelb94Meta } from "/app/apps/funzcity/pages/piggy-bank.vue?macro=true";
import { default as prize_45dropsxOHJz8hAmjMeta } from "/app/apps/funzcity/pages/prize-drops.vue?macro=true";
import { default as promotionsW78uq4ez7TMeta } from "/app/apps/funzcity/pages/promotions.vue?macro=true";
import { default as racesLJQRWfeGVXMeta } from "/app/apps/funzcity/pages/races.vue?macro=true";
import { default as referralqJsmNJbQf7Meta } from "/app/apps/funzcity/pages/referral.vue?macro=true";
import { default as scratch_45cardsNij4YFb7AdMeta } from "/app/apps/funzcity/pages/scratch-cards.vue?macro=true";
import { default as vip_45clubcYZam3sZFkMeta } from "/app/apps/funzcity/pages/vip-club.vue?macro=true";
export default [
  {
    name: indexjS2aXA914KMeta?.name ?? "affiliates___en",
    path: indexjS2aXA914KMeta?.path ?? "/affiliates",
    meta: indexjS2aXA914KMeta || {},
    alias: indexjS2aXA914KMeta?.alias || [],
    redirect: indexjS2aXA914KMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: partners_45accountmRH2alWi8NMeta?.name ?? "affiliates-partners-account___en",
    path: partners_45accountmRH2alWi8NMeta?.path ?? "/affiliates/partners-account",
    meta: partners_45accountmRH2alWi8NMeta || {},
    alias: partners_45accountmRH2alWi8NMeta?.alias || [],
    redirect: partners_45accountmRH2alWi8NMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/affiliates/partners-account.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordk1ecCugyh3Meta?.name ?? "change-password___en",
    path: change_45passwordk1ecCugyh3Meta?.path ?? "/change-password",
    meta: change_45passwordk1ecCugyh3Meta || {},
    alias: change_45passwordk1ecCugyh3Meta?.alias || [],
    redirect: change_45passwordk1ecCugyh3Meta?.redirect,
    component: () => import("/app/apps/funzcity/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: complete_45profilerGCNiIT3mJMeta?.name ?? "complete-profile___en",
    path: complete_45profilerGCNiIT3mJMeta?.path ?? "/complete-profile",
    meta: complete_45profilerGCNiIT3mJMeta || {},
    alias: complete_45profilerGCNiIT3mJMeta?.alias || [],
    redirect: complete_45profilerGCNiIT3mJMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/complete-profile.vue").then(m => m.default || m)
  },
  {
    name: email_45edit94qz6GdewhMeta?.name ?? "email-edit___en",
    path: email_45edit94qz6GdewhMeta?.path ?? "/email-edit",
    meta: email_45edit94qz6GdewhMeta || {},
    alias: email_45edit94qz6GdewhMeta?.alias || [],
    redirect: email_45edit94qz6GdewhMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/email-edit.vue").then(m => m.default || m)
  },
  {
    name: email_45verifyDy5IKvf2j2Meta?.name ?? "email-verify___en",
    path: email_45verifyDy5IKvf2j2Meta?.path ?? "/email-verify",
    meta: email_45verifyDy5IKvf2j2Meta || {},
    alias: email_45verifyDy5IKvf2j2Meta?.alias || [],
    redirect: email_45verifyDy5IKvf2j2Meta?.redirect,
    component: () => import("/app/apps/funzcity/pages/email-verify.vue").then(m => m.default || m)
  },
  {
    name: gametzV2dJHwoHMeta?.name ?? "game___en",
    path: gametzV2dJHwoHMeta?.path ?? "/game",
    meta: gametzV2dJHwoHMeta || {},
    alias: gametzV2dJHwoHMeta?.alias || [],
    redirect: gametzV2dJHwoHMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/game.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93grvT63ebMaMeta?.name ?? "games-pageName___en",
    path: _91pageName_93grvT63ebMaMeta?.path ?? "/games/:pageName()",
    meta: _91pageName_93grvT63ebMaMeta || {},
    alias: _91pageName_93grvT63ebMaMeta?.alias || [],
    redirect: _91pageName_93grvT63ebMaMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/games/[pageName].vue").then(m => m.default || m)
  },
  {
    name: how_45it_45works84Pkx0ycvVMeta?.name ?? "how-it-works___en",
    path: how_45it_45works84Pkx0ycvVMeta?.path ?? "/how-it-works",
    meta: how_45it_45works84Pkx0ycvVMeta || {},
    alias: how_45it_45works84Pkx0ycvVMeta?.alias || [],
    redirect: how_45it_45works84Pkx0ycvVMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexZdJHgWvbPZMeta?.name ?? "index___en",
    path: indexZdJHgWvbPZMeta?.path ?? "/",
    meta: indexZdJHgWvbPZMeta || {},
    alias: indexZdJHgWvbPZMeta?.alias || [],
    redirect: indexZdJHgWvbPZMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93x9AdqUL0X0Meta?.name ?? "issues-pageName___en",
    path: _91pageName_93x9AdqUL0X0Meta?.path ?? "/issues/:pageName()",
    meta: _91pageName_93x9AdqUL0X0Meta || {},
    alias: _91pageName_93x9AdqUL0X0Meta?.alias || [],
    redirect: _91pageName_93x9AdqUL0X0Meta?.redirect,
    component: () => import("/app/apps/funzcity/pages/issues/[pageName].vue").then(m => m.default || m)
  },
  {
    name: favoritesZuc3OG8iwyMeta?.name ?? "issues-favorites___en",
    path: favoritesZuc3OG8iwyMeta?.path ?? "/issues/favorites",
    meta: favoritesZuc3OG8iwyMeta || {},
    alias: favoritesZuc3OG8iwyMeta?.alias || [],
    redirect: favoritesZuc3OG8iwyMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/issues/favorites.vue").then(m => m.default || m)
  },
  {
    name: _91name_93SwpyA1Awq5Meta?.name ?? "page-name___en",
    path: _91name_93SwpyA1Awq5Meta?.path ?? "/page/:name()",
    meta: _91name_93SwpyA1Awq5Meta || {},
    alias: _91name_93SwpyA1Awq5Meta?.alias || [],
    redirect: _91name_93SwpyA1Awq5Meta?.redirect,
    component: () => import("/app/apps/funzcity/pages/page/[name].vue").then(m => m.default || m)
  },
  {
    name: phone_45edit2IHaNtm4yvMeta?.name ?? "phone-edit___en",
    path: phone_45edit2IHaNtm4yvMeta?.path ?? "/phone-edit",
    meta: phone_45edit2IHaNtm4yvMeta || {},
    alias: phone_45edit2IHaNtm4yvMeta?.alias || [],
    redirect: phone_45edit2IHaNtm4yvMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/phone-edit.vue").then(m => m.default || m)
  },
  {
    name: phone_45verifydHbWs8fqIXMeta?.name ?? "phone-verify___en",
    path: phone_45verifydHbWs8fqIXMeta?.path ?? "/phone-verify",
    meta: phone_45verifydHbWs8fqIXMeta || {},
    alias: phone_45verifydHbWs8fqIXMeta?.alias || [],
    redirect: phone_45verifydHbWs8fqIXMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/phone-verify.vue").then(m => m.default || m)
  },
  {
    name: piggy_45bankEbyZyelb94Meta?.name ?? "piggy-bank___en",
    path: piggy_45bankEbyZyelb94Meta?.path ?? "/piggy-bank",
    meta: piggy_45bankEbyZyelb94Meta || {},
    alias: piggy_45bankEbyZyelb94Meta?.alias || [],
    redirect: piggy_45bankEbyZyelb94Meta?.redirect,
    component: () => import("/app/apps/funzcity/pages/piggy-bank.vue").then(m => m.default || m)
  },
  {
    name: prize_45dropsxOHJz8hAmjMeta?.name ?? "prize-drops___en",
    path: prize_45dropsxOHJz8hAmjMeta?.path ?? "/prize-drops",
    meta: prize_45dropsxOHJz8hAmjMeta || {},
    alias: prize_45dropsxOHJz8hAmjMeta?.alias || [],
    redirect: prize_45dropsxOHJz8hAmjMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/prize-drops.vue").then(m => m.default || m)
  },
  {
    name: promotionsW78uq4ez7TMeta?.name ?? "promotions___en",
    path: promotionsW78uq4ez7TMeta?.path ?? "/promotions",
    meta: promotionsW78uq4ez7TMeta || {},
    alias: promotionsW78uq4ez7TMeta?.alias || [],
    redirect: promotionsW78uq4ez7TMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: racesLJQRWfeGVXMeta?.name ?? "races___en",
    path: racesLJQRWfeGVXMeta?.path ?? "/races",
    meta: racesLJQRWfeGVXMeta || {},
    alias: racesLJQRWfeGVXMeta?.alias || [],
    redirect: racesLJQRWfeGVXMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/races.vue").then(m => m.default || m)
  },
  {
    name: referralqJsmNJbQf7Meta?.name ?? "referral___en",
    path: referralqJsmNJbQf7Meta?.path ?? "/referral",
    meta: referralqJsmNJbQf7Meta || {},
    alias: referralqJsmNJbQf7Meta?.alias || [],
    redirect: referralqJsmNJbQf7Meta?.redirect,
    component: () => import("/app/apps/funzcity/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: scratch_45cardsNij4YFb7AdMeta?.name ?? "scratch-cards___en",
    path: scratch_45cardsNij4YFb7AdMeta?.path ?? "/scratch-cards",
    meta: scratch_45cardsNij4YFb7AdMeta || {},
    alias: scratch_45cardsNij4YFb7AdMeta?.alias || [],
    redirect: scratch_45cardsNij4YFb7AdMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/scratch-cards.vue").then(m => m.default || m)
  },
  {
    name: vip_45clubcYZam3sZFkMeta?.name ?? "vip-club___en",
    path: vip_45clubcYZam3sZFkMeta?.path ?? "/vip-club",
    meta: vip_45clubcYZam3sZFkMeta || {},
    alias: vip_45clubcYZam3sZFkMeta?.alias || [],
    redirect: vip_45clubcYZam3sZFkMeta?.redirect,
    component: () => import("/app/apps/funzcity/pages/vip-club.vue").then(m => m.default || m)
  }
]