import { apiClient, v1, type ExtractFromAPI } from "@netgame/openapi";

import useAppInitData from "./useAppInitData";
import type { AsyncData } from "./useAsync";
import useAsyncFetch from "./useAsyncFetch";

type SeasonsResponse = ExtractFromAPI<v1.paths, "/rest/seasons/season/", "get">;

const useSeasonsData = ({ immediate = true } = {}) => {
	const { data: appInitData } = useAppInitData();
	const isLoading = ref(true);

	const seasonInitData = computed(() => appInitData.value?.season);

	const data: AsyncData<SeasonsResponse, null> = useAsyncFetch({
		path: `/rest/seasons/${seasonInitData.value?.slug || "season"}/`,
		method: "get",
		options: {
			immediate: immediate && !!seasonInitData.value?.slug,
			lazy: true,
			server: false,
			default: () => ({
				levels: [{ currentExperience: 0, neededExperience: 100, prize: 0 }]
			})
		},
		fetchOptions: () => ({
			onRequest: () => {
				isLoading.value = true;
			},
			onResponse: () => {
				isLoading.value = false;
			}
		})
	});

	const handleSwitchSeason = async () =>
		await apiClient({
			path: "/rest/seasons/switch/between-status-systems/",
			method: "get",
			options: {
				onResponse: ({ response }) => {
					if (response._data.success && appInitData.value?.season) {
						appInitData.value.season.isActive = response._data.isActiveSeasonLevelSystem;
					}
				}
			}
		});
	return { ...data, isLoading, seasonInitData, handleSwitchSeason };
};

export default useSeasonsData;
