import { apiClient } from "@netgame/openapi";

import useAppInitData from "./useAppInitData";

const useSubTournament = () => {
	const { data } = useAppInitData();
	const loadingSubscribtions = ref(false);

	const handleSubscribe = ({ id, isSub }: { id: number; isSub: boolean }) => {
		if (isSub) {
			return;
		}

		apiClient({
			path: "/rest/tournaments/{id}/subscribe/",
			method: "get",
			parameters: { path: { id } },
			options: {
				onRequest: () => {
					loadingSubscribtions.value = true;
				},
				onResponse: ({ response }) => {
					loadingSubscribtions.value = false;
					if (response._data.success && data.value?.TournamentsSubscriptions) {
						data.value.TournamentsSubscriptions.push(id);
					}
				},
				onRequestError: () => {
					loadingSubscribtions.value = false;
				},
				onResponseError: () => {
					loadingSubscribtions.value = false;
				}
			}
		});
	};

	return {
		loadingSubscribtions,
		handleSubscribe
	};
};

export default useSubTournament;
