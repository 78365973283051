<script lang="ts" setup>
const { t } = useT();
const prepareImgUrl = useImage();
const handleError = () => clearError({ redirect: "/" });
</script>

<template>
	<div>
		<NuxtLayout>
			<div class="error">
				<NuxtImg src="/nuxt-img/error/404.png" alt="404" class="error-img" format="webp" width="1028" height="543" />

				<picture class="build build__left">
					<source
						:srcset="
							prepareImgUrl('/nuxt-img/error/left-mob.png', {
								format: 'webp',
								width: 184,
								height: 250
							})
						"
						width="184"
						height="250"
						loading="lazy"
						media="(max-width: 1023px)"
					/>
					<img
						:src="
							prepareImgUrl('/nuxt-img/error/left.png', {
								format: 'webp',
								width: 472,
								height: 640
							})
						"
						alt="funzcity"
						width="472"
						height="640"
						loading="lazy"
					/>
				</picture>

				<picture class="build build__right">
					<source
						:srcset="
							prepareImgUrl('/nuxt-img/error/right-mob.png', {
								format: 'webp',
								width: 184,
								height: 229
							})
						"
						width="184"
						height="229"
						loading="lazy"
						media="(max-width: 1023px)"
					/>
					<img
						:src="
							prepareImgUrl('/nuxt-img/error/right.png', {
								format: 'webp',
								width: 498,
								height: 620
							})
						"
						alt="funzcity"
						width="498"
						height="620"
						loading="lazy"
					/>
				</picture>

				<div class="content">
					<AText class="title text-tlalnepantla" variant="tlaquepaque" :modifiers="['bold', 'center']" as="h2">{{
						t("Sorry, but that page doesn’t exist")
					}}</AText>
					<AButton variant="primary" class="home-back" size="xl" data-tid="error-return-home" @click="handleError">
						<AText class="text-tlalnepantla" variant="tuzla" :modifiers="['semibold']">{{ t("Return home") }}</AText>
					</AButton>
				</div>
			</div>
		</NuxtLayout>
		<ClientOnly>
			<SupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>

<style lang="scss" scoped>
.error {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: calc(100vh - 104px);
	background: url("/nuxt-img/error/bg.png") no-repeat center center / cover;

	@include media-breakpoint-down(lg) {
		height: calc(100vh - 220px);
	}

	@include media-breakpoint-down(md) {
		height: calc(100vh - 170px);
	}
}
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: gutter(2.5);

	@include media-breakpoint-down(sm) {
		gap: gutter(2);
	}
}

.home-back {
	width: 100%;
	max-width: 280px;

	@include media-breakpoint-down(sm) {
		max-width: 220px;
		height: 40px;
	}
}

.title {
	text-shadow: 0 0 6px var(--carapicuiba);
	position: relative;
	z-index: 2;
}

.build {
	position: absolute;
	bottom: 0;
	&__left {
		left: 0;
		height: 640px;

		@include media-breakpoint-down(lg) {
			height: 250px;
		}
	}

	&__right {
		right: 0;
		height: 620px;

		@include media-breakpoint-down(lg) {
			height: 229px;
		}
	}
}

.error-img {
	max-width: 100%;
	height: auto;
	object-fit: cover;

	@include media-breakpoint-down(sm) {
		margin-bottom: gutter(5);
	}
}
</style>
